// eslint-disable-next-line
import React, { useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { setActivityEnable } from "../../../../../../store/reducers/contracts";
import { useDispatch } from "react-redux";
const DUEDATE = "Due date";

const useStyles = {
  mainbox: {
    width: "100%",
    height: "auto",
    backgroundColor: "transparent",
  },
  intelligencedataview: {
    display: "flex",
    displayDirection: "row",
    height: 100,
  },
};

const DateView = (props) => {
  const disp = useDispatch();
  const dateRef = useRef(null);

  const handleDateChange = (e) => {
    props.updateDate(e.target.value);
    disp(setActivityEnable({ id: 4, enable: true }));
    // const completionStatus = submissionCompletionStatus({})
    // disp(getOrderManageObj({ status: completionStatus, id: 1 }))
  };
  const getValue =
    typeof props?.value === "object" ? props?.value?.value : props?.value;

  return (
    <Box sx={{ pt: 1 }}>
      <Typography
        sx={{ fontSize: 11 }}
        style={{ ...useStyles.Fieldtitle, textAlign: "start" }}
        component="div"
      >
        {"Due Date"}
      </Typography>
      <Paper elevation={0} style={{ ...useStyles.FieldBase }}>
        <input
          className="datestyle"
          style={{ width: "117%", height: "35px" }}
          type="date"
          value={getValue}
          onChange={handleDateChange}
          onClick={() => {
            // open the datepicker when click input type date
            if (dateRef?.current) dateRef.current?.showPicker();
          }}
          ref={dateRef}
        ></input>
      </Paper>
    </Box>
  );
};

export default function MarketIntelligence(props) {
  // const [DueDate, setDueDate] = useState();
  // const [TargetValue, setTargetValue] = useState();
  // const UpdateTargetArea = (itm) => setTargetValue(itm);
  const UpdateDueDate = (itm) => props.setDueDate(itm);

  return (
    <Box elevation={0} style={{ ...useStyles.mainbox }}>
      <Paper
        sx={{ pt: 2, pl: 0 }}
        elevation={0}
        style={{ ...useStyles.intelligencedataview }}
      >
        {/* <MDDateField  paddingtop={0} value={props.selectedDate} UpdateSubmittedBy={(value) => UpdateDueDate(value)} title={DUEDATE} width={'150px'}/> */}
        <DateView
          paddingtop={0}
          title={DUEDATE}
          value={props.selectedDate}
          updateDate={(value) => UpdateDueDate(value)}
          style={{ width: "150px" }}
        />
      </Paper>
    </Box>
  );
}
