import Authorization from "./authorization";

const getLocalStorageValue = (key) => {
  
  try {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  } catch (error) {
    Authorization.logout();
  }
};

const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const removeLocalStorageValue = (key) => {
  return localStorage.removeItem(key);
};

const LocalStorage = {
  getLocalStorageValue,
  setLocalStorageValue,
  removeLocalStorageValue,
};

export default LocalStorage;
