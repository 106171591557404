import { useState, useCallback, memo, useEffect } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { ErrorMsg } from "../../utils/helper";
import { apiURL } from "../../services/apiUrlConfig";
import { getRequest } from "../../services/apiUtils";
import { openSnackbar } from "../../components/ui/Toastr";
import Loader from "../../components/ui/Loader";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import "../../assets/scss/trialLocation.scss";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import Authorization from "../../utils/authorization";
import { CustomButton } from "../../components/ui/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Accordions from "../../components/ui/Accordion";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#eeeeee",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  height: "100vh",
  overflowX: "auto",
}));

const center = {
  lat: 0,
  lng: 0,
};

const trialLocDetlsLabel = [
  { label: "Grower", condKey: "growerName", value: "" },
  { label: "Dealer", condKey: "dealerName", value: "" },
  { label: "Record Type(Crop)", condKey: "recordType", value: "" },
  { label: "Variety", condKey: "variety", value: "" },
  { label: "Status", condKey: "status", value: "" },
];

const AccrodionList = ({ name, expanded, handleChange, children }) => {
  return (
    <Accordions
      accordionSX={{
        "&::before": {
          backgroundColor: "#e0e0e0",
        },
        "&.Mui-expanded": {
          margin: "8px 0",
        },
        "& .MuiAccordion-region": {
          height: expanded === name ? "auto" : 0,
        },
        "& .MuiAccordionDetails-root": {
          display: expanded === name ? "block" : "none",
        },
      }}
      titleName={name}
      handleChange={handleChange}
      expanded={expanded}
    >
      {children}
    </Accordions>
  );
};
function CustomMaps() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script-ba+",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
  });
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({});
  const [isInfo, setIsInfo] = useState(false);
  // const [addressContent, setAddressContent] = useState("");
  const [markerPosData, setMarkerPosData] = useState([]);
  const [isLoader, SetLoader] = useState(false);
  const [activeMarkerId, setActiveMarkerId] = useState("");
  const [selectedMarkObj, setSelectedMarkObj] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [isViewAssessment, setIsViewAssessment] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState([]);

  const [trialLocationData, setTrialLocationData] =
    useState(trialLocDetlsLabel);

  const { countryCode } = useSelector((state) => state.common);
  const authUser = Authorization.getAuthUser();

  useEffect(() => {
    if (countryCode) getTrailLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  useEffect(() => {
    if (activeMarkerId && markerPosData?.length > 0) {
      const filterByMarkerID = markerPosData.find(
        (mar) => mar?.id === activeMarkerId
      );
      let objectValue = filterByMarkerID?.trials?.[0];
      let keyAndValueArray = [];
      for (let property in objectValue) {
        let obj = { key: property, value: objectValue[property] };
        keyAndValueArray.push({ ...obj });
      }
      if (trialLocationData?.length && keyAndValueArray?.length) {
        const updatedResult = trialLocationData.map((tld) => {
          keyAndValueArray.map((kva) => {
            if (tld.condKey === kva.key) {
              return (tld = { ...tld, value: kva.value });
            }
            return tld;
          });
          return tld;
        });
        setTrialLocationData(isInfo ? updatedResult : trialLocDetlsLabel);
      }
      setSelectedMarkObj(isInfo ? filterByMarkerID : {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarkerId, isInfo]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getTrailLocation = () => {
    const { trials } = apiURL;
    SetLoader(true);
    setIsInfo(false);
    getRequest({
      url:`${trials.getLocation}/${authUser?.id}`,
      queryParams: {
        countryCode: countryCode === "ALL" ? null : countryCode
      },
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body;
        if (result?.length > 0) {
          const getLatLngResult = result.map((rlt, index) => {
            return (rlt = { ...rlt, id: "id" + index });
          });
          let postiton = {
            lat: getLatLngResult[0]?.lat,
            lng: getLatLngResult[0]?.long,
          };
          setCurrentPosition(postiton);
          setMarkerPosData(getLatLngResult);
        } else {
          setMarkerPosData([]);
          setSelectedMarkObj({});
          setActiveMarkerId("");
          setTrialLocationData(trialLocDetlsLabel);
          setIsInfo(false);
          openSnackbar({ message: "No records found!", variant: "error" });
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
 
  const handleMarker = (e, obj) => {
    if (activeMarkerId === obj?.id) {
      setIsInfo(!isInfo);
    } else {
      setIsInfo(true);
    }
    setActiveMarkerId(obj?.id);
    setIsViewAssessment(false);
    setAssessmentDetails([]);
  };

  const handleClose = () => {
    setSelectedMarkObj({});
    setActiveMarkerId("");
    setIsInfo(false);
  };
  const handleAssessment = (trialId) => {
    getAssessmentsData(trialId);
  };

  const getAssessmentsData = (trialID) => {
    const { trials } = apiURL;
    SetLoader(true);
    getRequest({
      url:`${trials.getAssessment}/${authUser?.id}`,
      queryParams: {
        countryCode: countryCode === "ALL" ? null : countryCode,
        trialId: trialID,
      },
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body?.data;
        if (result?.length > 0) {
          setAssessmentDetails(result);
          setIsViewAssessment(true);
        } else {
          openSnackbar({
            message: "No assessment records found!",
            variant: "error",
          });
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  return isLoaded ? (
    <Box sx={{ flexGrow: 1 }}>
      {isLoader && <Loader />}
      <Grid container>
        <Grid item xs={isInfo ? 8 : 12}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100vh",
            }}
            center={countryCode === "ALL" ? center : currentPosition}
            zoom={2}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{ streetViewControl: false }}
          >
            {/* Child components, such as markers, info windows, etc. */}
            {markerPosData?.length > 0 &&
              markerPosData.map((marposd, marIndex) => {
                let postiton = { lat: marposd?.lat, lng: marposd?.long };
                return (
                  <MarkerF
                    key={marIndex}
                    position={postiton}
                    label={{
                      text: marposd?.count.toString(),
                      color:
                        activeMarkerId === marposd?.id
                          ? isInfo
                            ? "black"
                            : "white"
                          : "white",
                    }}
                    icon={{
                      url: `${window.location.origin}/${
                        activeMarkerId === marposd?.id
                          ? isInfo
                            ? "yellowmarker"
                            : "blackmarker"
                          : "blackmarker"
                      }.svg`,
                      scaledSize: new window.google.maps.Size(70, 70),
                      // anchor: new window.google.maps.Point(25, 50),
                      labelOrigin: new window.google.maps.Point(35, 28),
                    }}
                    onClick={(e) => handleMarker(e, marposd)}
                    noClustererRedraw={true}
                  >
                  </MarkerF>
                );
              })}
          </GoogleMap>
        </Grid>
        {isInfo ? (
          <Grid item xs={4}>
            <Item>
              <Box
                sx={{ backgroundColor: "#66B512", color: "#ffffff" }}
                p={1}
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={0.5}
                >
                  {isViewAssessment && (
                    <KeyboardBackspaceIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsViewAssessment(false)}
                    />
                  )}
                  {/* <ArrowBackIcon /> */}
                  <Typography variant="h5">
                    {!isViewAssessment ? "Trial Location" : "Assessments"}
                    &nbsp;Details
                  </Typography>
                </Box>

                {!isViewAssessment && (
                  <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
                )}
              </Box>
              {!isViewAssessment && selectedMarkObj?.trials?.length > 0
                ? selectedMarkObj?.trials.map((tl, tlIndex) => {
                    return (
                      <AccrodionList
                        name={tl?.name}
                        handleChange={handleChange(tl?.name)}
                        expanded={expanded}
                        key={tlIndex}
                      >
                        <div className="trial-location-table">
                          <table style={{ width: "100%" }}>
                            <tr>
                              <th>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Grower
                                </Typography>
                              </th>
                              <td>
                                <Typography variant="subtitle2">
                                  {tl?.growerName}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Dealer
                                </Typography>
                              </th>
                              <td>
                                <Typography variant="subtitle2">
                                  {tl?.dealerName}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Variety
                                </Typography>
                              </th>
                              <td>
                                <Typography variant="subtitle2">
                                  {tl?.variety}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Status
                                </Typography>
                              </th>
                              <td>
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={tl?.status}
                                    sx={{
                                      color: "#fff",
                                      bgcolor:
                                        tl?.status === "Completed"
                                          ? "success.main"
                                          : tl?.status === "Cancelled"
                                          ? "error.main"
                                          : "primary.main",
                                    }}
                                  />
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Crop
                                </Typography>
                              </th>
                              <td>
                                <Typography variant="subtitle2">
                                  {tl?.recordType}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <Typography variant="subtitle1">
                                  Assessments
                                </Typography>
                              </th>
                              <td>
                                <CustomButton
                                  label="View"
                                  sx={{
                                    color: "#2B6636",
                                    borderRadius: "28px",
                                    backgroundColor: "#dcedc8",
                                    opacity: "1",
                                    "&:hover": {
                                      backgroundColor: "#dcedc8",
                                      color: "#66B512",
                                      border: "1.35px solid #66B512",
                                    },
                                  }}
                                  handleClick={() =>
                                    handleAssessment(tl?.trialId)
                                  }
                                ></CustomButton>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </AccrodionList>
                    );
                  })
                : null}
              {isViewAssessment &&
                assessmentDetails?.length > 0 &&
                assessmentDetails.map((asd, asdIndex) => {
                  return (
                    <AccrodionList
                      name={asd?.name}
                      handleChange={handleChange(asd?.name)}
                      expanded={expanded}
                      key={asdIndex}
                    >
                      <div className="trial-location-table">
                        <table style={{ width: "100%" }}>
                          <tr>
                            <th>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Grower
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="subtitle2">
                                {asd?.growername}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Dealer
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="subtitle2">
                                {asd?.dealername}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Variety
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="subtitle2">
                                {asd?.variety}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Grower Rating
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="subtitle2">
                                {asd?.growerrating}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Grower Comment
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="subtitle2">
                                {asd?.growercomment}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Sales Rating
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="subtitle2">
                                {asd?.salesrating}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Sales Comment
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="subtitle2">
                                {asd?.salescomment}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </AccrodionList>
                  );
                })}
            </Item>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  ) : (
    <></>
  );
}

export default memo(CustomMaps);
