import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    uploadDocumentDetails: {},
    uploadDocumentLoading: false,
    uploadDocumentError: false,
    uploadDocument: []
}
const userReducer = createSlice({

    name: 'uploadDocument',
    initialState,
    reducers: {
        getDocumentData(state, action) {
            state.uploadDocument = [...action.payload]
        },
        getDocumentHashData(state) {
            return { ...state, uploadDocumentLoading: true };
        },
        getDocument(state) {
            return { ...state, uploadDocumentLoading: true };
        },
        getDocument_successful(state, action) {
            return {
                ...state, uploadDocumentDetails: action.payload,
                uploadDocumentError: false
            }
        },
        getDocument_error(state, action) {
            return {
                ...state, uploadDocumentDetails: action.payload.body,
                createDocumentError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, uploadDocumentLoading: false,
                createDocumentError: false,
                uploadDocumentDetails: {}
            };
        },
    }
})
export const {
    getDocumentData,
    getDocumentHashData,
    getDocument,
    getDocument_successful,
    getDocument_error,
    resetStatus
} = userReducer.actions;

export default userReducer.reducer