import {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
// import { store } from "./store";
import ThemeCustomization from "./themes";
// import loginReducer from '../src/features/login/loginSlice'
import createSagaMiddleware from "redux-saga";
import rootReducer from './store/reducers'
import { configureStore } from '@reduxjs/toolkit';
import "./assets/scss/common.scss"
import sagaService from "./saga/sagaService"
const sagaMiddleware = createSagaMiddleware();

 const store = configureStore({
   reducer:rootReducer,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware),
 })

 sagaMiddleware.run(sagaService)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeCustomization>
        <AppRoutes />
      </ThemeCustomization>
    </Provider>
  </StrictMode>
);
