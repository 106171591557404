/* eslint-disable react-hooks/exhaustive-deps */
import {
  memo,
  Fragment,
  React,
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import dayjs from "dayjs";
import Loader from "../../../../../../components/ui/Loader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CustomTable from "../../../../../../components/ui/MainTable";
import util from "util";
import Authorization from "../../../../../../utils/authorization";
import { apiURL } from "../../../../../../services/apiUrlConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  resetStatus,
  updateforeCastInfo,
  getforeCastInfo,
} from "../../../../../../saga/saga/foreCastSaga";
import { openSnackbar } from "../../../../../../components/ui/Toastr";
import {
  getOrderManageObj,
  getForeCastAccSelected,
  setActivityEnable,
  setForecastSelection
} from "../../../../../../store/reducers/contracts";
import "./forecastinfo.scss";

export const SELECTALL = "SA";

const SUCCESS = 200;
const ACTIONTYPE = "EDIT";
const ALWAYSOPEN = 'yes'
const ERROR_MSG = "Please check internet or try again!";
// const ERROR_MSG_FIELDS = 'Please enter valid number'
// const ERROR_MSG_SUBMISSION = 'Please fill the required fields!'

const CustomMenu = styled(MenuItem)({
  height:30,
  fontSize:11,
  letterSpacing:0.5
});
const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-input':{
   display:'block',
   textOverflow: "ellipsis",
 }
 }));

function createData(
  sfid,
  salestarget,
  accname,
  country,
  varieties,
  isselected
) {
  return {
    sfid,
    salestarget,
    accname,
    country,
    varieties,
    isselected,
  };
}

const headCells = [
  {
    id: "salestarget",
    numeric: false,
    disablePadding: true,
    label: "Inc. PPP sales target (FY) ID",
    minWidth: 150,
  },
  {
    id: "accname",
    numeric: false,
    disablePadding: false,
    label: "Account name",
    minWidth: 100,
  },
  {
    id: "country",
    numeric: true,
    disablePadding: false,
    label: "Country",
    minWidth: 100,
  },
  {
    id: "varieties",
    numeric: true,
    disablePadding: false,
    label: "Variety",
    minWidth: 100,
  },
];

const ToggleButtonn = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#EBF0F2",
    borderRadius: 3,
    borderColor: "#00BCFF",
  },
  "&.MuiToggleButton-root": {
    outline: "lightgrey solid 1px",
    borderRadius: 3,
  },
  // border:'2px solid lightgrey',
  height: 40,
  padding: 0,
  marginRight: 10,
  // borderRadius:5,outline :'lightgrey initial 1px'
  width: 150,
  marginTop: 5,
  marginBottom: 5,
});
// const StyledListTyphgy = styled(DesktopDatePicker)(({ theme }) => ({
//     '& .MuiInputBase-input ': {
//         fontSize: 11,
//         width: 93,
//         height: 0
//         //  fontSize:11.5
//     },
//     '& .MuiInputBase-root': {
//         marginRight: 10,
//         // borderColor: 'red',
//         marginLeft: 9,
//         height: 45,
//         borderWidth: 1
//     },
//     '& .MuiSvgIcon-root': {
//         width: '0.7em',
//         height: '0.7em'
//     }
// }));

// const foreCastTheme = (outerTheme) =>
//     createTheme({
//         components: {
//             MuiTextField: {
//                 styleOverrides: {
//                     root: {
//                         '--TextField-brandBorderColor': '#E0E3E7',
//                         '--TextField-brandBorderHoverColor': '#B2BAC2',
//                         '--TextField-brandBorderFocusedColor': '#EBF0F2',
//                         '& label.Mui-focused': {
//                             color: 'var(--TextField-brandBorderFocusedColor)',
//                         },
//                     },
//                 },
//             },
//             MuiOutlinedInput: {
//                 styleOverrides: {
//                     notchedOutline: {
//                         borderColor: 'var(--TextField-brandBorderColor)',
//                     },
//                     root: {
//                         [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
//                             borderColor: 'var(--TextField-brandBorderHoverColor)',
//                         },
//                         [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
//                             borderColor: 'var(--TextField-brandBorderFocusedColor)',
//                         },
//                     },
//                 },
//             },
//         },
//     });

const useStyles = {
  ForeCastDetails: {
    width: "100%",
    backgroundColor: "transparent",
    paddingBottom: 10,
    border: "1px solid lightgrey",
  },
  ForeCastHeader: {
    backgroundColor: "#EBF0F2",
    height: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 10,
  },
  Fieldtitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 1,
  },
  FieldBase: {
    width: "100%",
    display: "flex",
  },
  sampleCheck: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  TogListText: {
    fontSize: 11,
    textTransform: "none",
  },
  ListOfVarietes: {
    // minHeight: 350,
    marginRight: 17,
  },
  Headerofvarieties: {
    width: "100%",
    height: 40,
    backgroundColor: "#EBF0F2",
    color: "black",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 10,
  },
};

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
}));

const numberOfDateFldsRequired = (selectedTyp) => {
  let dateFieldsForYear = [];
  switch (selectedTyp) {
    case QUARTERLY:
      dateFieldsForYear = Array(12).fill("");
      break;
    case MONTHLY:
      dateFieldsForYear = Array(4).fill("");
      break;
    case YEARLY:
      dateFieldsForYear = Array(1).fill("");
      break;
    case TWICEAYEAR:
      dateFieldsForYear = Array(1).fill("");
      break;
    default:
      dateFieldsForYear = Array(1).fill("");
      break;
  }
  return dateFieldsForYear;
};

const numberOfDateFldsRequiredUpdate = (selectedTyp) => {
  let dateFieldsForYear = [];
  // const dateObj = { value: '', type: '', yearFor: '' }
  switch (selectedTyp) {
    case 0:
      dateFieldsForYear = Array(12).fill("");
      break;
    case 1:
      dateFieldsForYear = Array(4).fill("");
      break;
    case 2:
      dateFieldsForYear = Array(2).fill("");
      break;
    case 3:
      dateFieldsForYear = Array(1).fill("");
      break;
    default:
      dateFieldsForYear = Array(1).fill("");
      break;
  }
  return dateFieldsForYear;
};

const ForeCastDetails = (props) => {
  const [selectedType, setselectedType] = useState();
  // const [_, setDateValuesObj] = useState([]);
  const [changeFor, setChangeFor] = useState("Frequency");
  const [open, setOpen] = useState(ALWAYSOPEN === "yes");
  const handleClick = () => {
    setOpen(!open);
  };

  const exactFields = async () => {
    // const requiredDateFields = await numberOfDateFldsRequired(selectedType)
    // setDateValuesObj(requiredDateFields)
  };

  useEffect(() => {
    const inputDetail = props.src.Frequency;
    const initialInputLoad =
      inputDetail?.length > 0 &&
      inputDetail.filter((itm) => itm?.selected === true);
    setselectedType(
      keyTypes[initialInputLoad?.length > 0 ? initialInputLoad[0]?.name : "NA"]
    );
    if (changeFor === "Frequency") {
      exactFields();
    }
  }, [props, selectedType, changeFor]);

  const getTitle = (year, title, inputDetails, value) => {
    setselectedType(keyTypes[title]);
    setChangeFor(inputDetails);
    props.updatedSelection(year, title, inputDetails, value);
  };

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton
        disableRipple
        onClick={handleClick}
        style={{ padding: 0, backgroundColor: "#EBF0F2", margin: 0 }}
      >
        <Typography
          sx={{ fontSize: 12 }}
          style={{ ...useStyles.ForeCastHeader }}
          component="div"
        >
          {props.src.year}
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" >
        {props.ind > 0&&<Paper style={{display:'flex',paddingLeft:10}} elevation={0}> 
          <FormControlLabel control={<Checkbox
      checked={props.ind === 2 ? props.selectPrev : props.selectNext }
      sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
      onChange={()=>props.changePrevSel(props.ind)}
      inputProps={{ 'aria-label': 'controlled' }}
    />} label={<span style={{fontSize:12}}>{'Same as previous year'}</span>} />
          </Paper>}
        <FieldToggle
          preCheck={props.preCheck}
          updatedSelection={(value, title) =>
            getTitle(props.src.year, title, "inputDetails", value)
          }
          title={"Input details"}
          inputDetails={props.src.inputDetails}
        />
        <FieldToggle
          preCheck={props.preCheck}
          updatedSelection={(value, title) =>
            getTitle(props.src.year, title, "Frequency", value)
          }
          title={"Frequency"}
          inputDetails={props.src.Frequency}
        />
        <Box
          className="dateinputs-forecast"
          sx={{
            gridGap: 3.5,
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 156px))",
          }}
        >
          {props.src?.SubmittedBy?.length > 0 &&
            props.src.SubmittedBy.map((item, index) => {
              return (
                <DateView
                  all={props.src.SubmittedBy}
                  allowNav={props.allowNav}
                  freq={props.src?.SubmittedBy?.length||0}
                  id={index}
                  key={index + 1}
                  value={item}
                  updateDate={(value) =>
                    props.updateDate(
                      props.src.year,
                      "SubmittedBy",
                      index,
                      value
                    )
                  }
                  title={"SubmittedBy"}
                  inputDetails={props.src.SubmittedBy}
                />
              );
            })}
        </Box>
      </Collapse>
    </List>
  );
};
const TogList = (props) => {
  return (
    <ToggleButtonn
      disableRipple
      style={{ ...useStyles.selecttoggle
        ,borderWidth:'0px',
        borderColor:props.Item === props.alignmen?'#00BCFF':'lightgrey',
        backgroundColor:props.Item === props.alignmen ? "#EBF0F2" : "transparent",
      }}
      value={props.alignmen}
      aria-label="left aligned"
    >
      <Paper
        elevation={0}
        style={{
          ...useStyles.sampleCheck,
          backgroundColor:
            props.Item === props.alignmen ? "#EBF0F2" : "transparent",
        }}
      >
        {props.Item === props.alignmen && (
          <CheckIcon color="primary" style={{ opacity: 0.5 }} />
        )}
        <Typography
          sx={{
            fontSize: 11,
            pl: 1,
            color: props.Item === props.alignmen ? "#00BCFF" : "#677879",
          }}
          style={{ ...useStyles.TogListText }}
        >
          {props.name}
        </Typography>
      </Paper>
    </ToggleButtonn>
  );
};

const Field = (props) => {
  const [itemChecked, setitemChecked] = useState();
  const handleAlignment = (event, newitemChecked, name) => {
    if (newitemChecked !== null) {
      setitemChecked(newitemChecked);
      props.updatedSelection(newitemChecked, event.target.innerHTML);
      //console.log("lskjdjd::",newitemChecked)
    }
  };
  return (
    <ToggleButtonGroup
      value={itemChecked}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      style={{ flexWrap: "wrap", paddingLeft: 8 }}
    >
      {props.inputDetails &&
        props.inputDetails.map((item) => {
          return (
            <TogList
              Item={item.selected && item.id}
              name={item.name}
              alignmen={item.id}
            />
          );
        })}
    </ToggleButtonGroup>
  );
};

const getSubmittedVariety =(count)=>{
  if(count === 12)
  {
    return ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  }else if(count === 4){
    return ['Q1','Q2','Q3','Q4']
  }else{
    return ['H1','H2']
  }
}

const DateView = (props) => {
  const handleDateChange = (e) => {
    props.updateDate(e.target.value);
  };
  const submittedVarieties = getSubmittedVariety(props.freq)
  const getValue =
    typeof props?.value === "object" ? props?.value?.value : props?.value;
  const dateRef = useRef(null);
  return (
    <Box sx={{ pt: 1 }}>
      <Typography
        sx={{ fontSize: 11 }}
        style={{ ...useStyles.Fieldtitle,fontSize: '11px' }}
        component="div"
      >
        {props.all?.length > 1
          ? `Submitted by : ${submittedVarieties[props.id]}`
          : "Submitted by"}
      </Typography>
      <Paper elevation={0} style={{ ...useStyles.FieldBase }}>
        <input
          className="datestyle"
          type="date"
          ref={dateRef}
          value={getValue}
          autoShow={false}
          onChange={handleDateChange}
          onClick={() => {
            // open the datepicker when click input type date
            if (dateRef?.current) dateRef.current?.showPicker();
          }}
        ></input>
      </Paper>
    </Box>
  );
};

const FieldToggle = (props) => {
  return (
    <Box sx={{ pt: 1 }}>
      <Typography
        sx={{ fontSize: 11 }}
        style={{ ...useStyles.Fieldtitle,fontSize: '11px' }}
        component="div"
      >
        {props.title}
      </Typography>
      <Paper elevation={0} style={{ ...useStyles.FieldBase }}>
        <Field
          preCheck={props.preCheck}
          updatedSelection={(value, title) =>
            props.updatedSelection(value, title)
          }
          inputDetails={props.inputDetails}
        />
      </Paper>
    </Box>
  );
};
//            return pageConfigSchema(itm.year, itm.inputdetails, itm.frequency, itm.dates)
const currentYearL = new Date().getFullYear();
const respFormat = [
  {
    year: currentYearL,
    id: 123,
    inputdetails: "MONTHLY",
    frequency: "MONTHLY",
    dates: ["", "", "", "", "", "", "", "", "", "", "", ""],
  }
  ,
  {
    year: currentYearL+1,
    id: 124,
    inputdetails: "MONTHLY",
    frequency: "MONTHLY",
    dates: ["", "", "", "", "", "", "", "", "", "", "", ""],
  },
  {
    year: currentYearL+2,
    id: 125,
    inputdetails: "MONTHLY",
    frequency: "MONTHLY",
    dates: ["", "", "", "", "", "", "", "", "", "", "", ""],
  },
];

const MONTHLY = "MONTHLY";
const QUARTERLY = "QUARTERLY";
const YEARLY = "YEARLY";
const TWICEAYEAR = "TWICEAYEAR";
const NA = "NOTAVAILABLE";

const keyTypes = {
  "Monthly Forecast": MONTHLY,
  "Quarterly Forecast": QUARTERLY,
  Monthly: MONTHLY,
  Quarterly: QUARTERLY,
  Yearly: YEARLY,
  "Twice a Year": TWICEAYEAR,
  NA: NA,
};

const sumitedDatesArrange = (year, input, frequency, submitteddates) => {
  const submitDates =
    submitteddates?.length > 0 &&
    submitteddates.map((itm, indx) => {
      return { name: "SubmittedBy", id: indx, year: year, value: itm };
    });
  const standardFieldref = numberOfDateFldsRequiredUpdate(frequency);

  const standardAssgFlds = standardFieldref.map((itm, index) => {
    if (submitDates[index]) {
      return submitDates[index];
    } else {
      return { name: "SubmittedBy", id: index, year: year, value: "" };
    }
  });
  return standardAssgFlds;
};

const pageConfigSchema = async (
  year,
  input,
  frequency,
  submitteddates,
  action
) => {
  const submitDates =
    submitteddates?.length > 0 &&
    submitteddates.map((itm, indx) => {
      return { name: "SubmittedBy", id: indx, year: year, value: itm };
    });
  const standardFieldref = await numberOfDateFldsRequired(frequency);
  // eslint-disable-next-line no-unused-vars
  const standardAssgFlds = standardFieldref.map((itm, index) => {
    if (submitDates[index]) {
      return submitDates[index]; 
    } else {
      return { name: "SubmittedBy", id: index, year: year, value: "" };
    }
  });

  const keyTypes = {
    MONTHLY: 0,
    QUARTERLY: 1,
    YEARLY: 3,
    TWICEAYEAR: 2,
  };

  const freqncy = keyTypes[frequency];
  // const sbmtDte = sumitedDatesArrange(year, input, freqncy, standardFieldref);
  // const sbmtDtedit = sumitedDatesArrange(year, input, freqncy, submitteddates)
  const schemaInsist = {
    year: year,
    inputDetails: [
      {
        name: "Monthly Forecast",
        id: 0,
        selected: input === MONTHLY ? true : false,
        year: year,
      },
      {
        name: "Quarterly Forecast",
        id: 1,
        selected: input === QUARTERLY ? true : false,
        year: year,
      },
    ],
    Frequency: [
      {
        name: "Monthly",
        id: 0,
        selected: freqncy === 0 ? true : false,
        year: year,
      },
      {
        name: "Quarterly",
        id: 1,
        selected: freqncy === 1 ? true : false,
        year: year,
      },
      {
        name: "Twice a Year",
        id: 2,
        selected: freqncy === 2 ? true : false,
        year: year,
      },
      {
        name: "Yearly",
        id: 3,
        selected: freqncy === 3 ? true : false,
        year: year,
      },
    ],
    SubmittedBy: action === "EDIT" ? submitDates : submitDates,
  };
  return schemaInsist;
};

const reverseParseFromCofig = (configObj) => {
  const inputDetails = configObj?.inputDetails || [];
  const frequencyDetails = configObj?.Frequency || [];
  const submittedDetails = configObj?.SubmittedBy || [];

  const inputSel =
    inputDetails?.length > 0 &&
    inputDetails.filter((itm) => itm?.selected === true);
  const frequenceSel =
    frequencyDetails?.length > 0 &&
    frequencyDetails.filter((itm) => itm?.selected === true);
  const submittedSel =
    submittedDetails?.length > 0 &&
    submittedDetails.map((itm) => {
      // console.log('lkjhjklllll::',itm)
      const findr = typeof itm === "object" ? itm.value?.length > 0 : itm;
      const farmatDate = findr ? dayjs(itm.value).format("YYYY-MM-DD") : "";
      // console.log('lkjhjkl::',farmatDate)
      // `${dayjs(itm.value).get('year')}-${dayjs(itm.value).get('month')}-${dayjs(itm.value).get('date')}`
      return farmatDate;
    });

  const reverseParseObj = {
    year: configObj.year,
    inputdetails: keyTypes[(inputSel?.length && inputSel[0].name) || "NA"],
    frequency: keyTypes[(frequenceSel?.length && frequenceSel[0].name) || "NA"],
    dates: submittedSel,
  };
  return reverseParseObj;
};

const currentYear = new Date().getFullYear();
const srcData = [
  {
    year: currentYear,
    inputDetails: [
      { name: "Monthly Forecast", id: 0, selected: false, year: 2024 },
      { name: "Quarterly Forecast", id: 1, selected: true, year: 2024 },
    ],
    Frequency: [
      { name: "Monthly", id: 0, selected: false, year: 2024 },
      { name: "Quarterly", id: 1, selected: false, year: 2024 },
      { name: "Yearly", id: 2, selected: true, year: 2024 },
      { name: "Twice a Year", id: 3, selected: false, year: 2024 },
    ],
    SubmittedBy: [{ name: "Monthly Forecast", id: 0, year: 2024 }],
  }
  ,
  {
    year: currentYear + 1,
    inputDetails: [
      { name: "Monthly Forecast", id: 0, selected: true, year: 2024 },
      { name: "Quarterly Forecast", id: 1, selected: false, year: 2024 },
    ],
    Frequency: [
      { name: "Quarterly", id: 0, selected: false, year: 2024 },
      { name: "Monthly", id: 1, selected: false, year: 2024 },
      { name: "Yearly", id: 2, selected: false, year: 2024 },
      { name: "Twice a Year", id: 3, selected: true, year: 2024 },
    ],
    SubmittedBy: [{ name: "Monthly Forecast", id: 0, year: 2024 }],
  },
  {
    year: currentYear + 2,
    inputDetails: [
      { name: "Monthly Forecast", id: 0, selected: true, year: 2024 },
      { name: "Quarterly Forecast", id: 1, selected: false, year: 2024 },
    ],
    Frequency: [
      { name: "Quarterly", id: 0, selected: false, year: 2024 },
      { name: "Monthly", id: 1, selected: false, year: 2024 },
      { name: "Yearly", id: 2, selected: true, year: 2024 },
      { name: "Twice a Year", id: 3, selected: false, year: 2024 },
    ],
    SubmittedBy: [
      { name: "Monthly Forecast", id: 0, year: 2024 },
      { name: "Monthly Forecast", id: 1, year: 2024 },
      { name: "Monthly Forecast", id: 2, year: 2024 },
    ],
  },
];

// const StyledTextfield = styled(TextField)(({ theme }) => ({
//     '& .MuiInputBase-inputSizeSmall': {
//         height: 16,
//         fontSize: 11.5
//     },
//     '& label.Mui-focused': {
//         color: 'var(--TextField-brandBorderFocusedColor)',
//     },
//     '& input:valid + fieldset': {
//         borderColor: '#00BCFF',
//         borderWidth: 1,
//         color: '#00BCFF'
//     },
// }));

const ForeCastInfo = forwardRef((props, ref) => {
  const [list, setlist] = useState([]);
    const [shallowList, setShallowList] = useState([]);  
    const [accList, setAccList] = useState([]);  
    const [countryList, setcountryList] = useState([]);  
    const [varietyList, setvarietyList] = useState([]);  

    // eslint-disable-next-line
    const [filacc, setFilacc] = useState('all');
    const [filCountry, setFillCountry] = useState('all');
    const [filVariety, setFillVariety] = useState('all');

  const [exisingForecast, setsetexisingForecast] = useState(respFormat);
  const [open, setOpen] = useState(true);
  const [allowPrev, setAllowPrev] = useState(false)
  const [allowNext, setAllowNext] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selectedYear, setSelectedYear] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [selectedFreq, setSelectedFreq] = useState(1);
  // const [selection, setSelection] = useState([]);

  const [allowNav, setAllowNav] = useState(false);

  const foreCastloading = useSelector(
    (state) => state.foreCastSaga.foreCastloading
  );
  const foreCastDetails = useSelector(
    (state) => state.foreCastSaga.foreCastDetails
  );
  const foreCastError = useSelector(
    (state) => state.foreCastSaga.salesVolumeError
  );
  const foreCastInfoDetails = useSelector(
    (state) => state.foreCastSaga.foreCast
  );
  const action = useSelector((state) => state.common.ContractActionType);
  // const [foreCastList, setForeCastList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [submitClicker, setSSubmitClicker] = useState(false);

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const getAccounts = useSelector((state) => state.contracts.accounts);
  const foreCastAccounts = useSelector((state) => state.contracts.foreCastAcc);
  const getSelection = useSelector((state) => state.contracts.forecastInfo);
  const getSrc = getSelection?.length > 0 ? getSelection : srcData
  const [src, setSrc] = useState(getSrc || []);

  const selectedAccountIds =
    getAccounts &&
    getAccounts.map((account) => {
      return account.id;
    });
  const getApi = apiURL.ppp.getForeCastInfo ?? "";
  const userDetaisl = Authorization.authUser;
  const updateApi = apiURL.ppp.updateForeCastInfo ?? "";

  const getForecastApi = util.format(getApi, userDetaisl.id);
  const postForecastApi = util.format(updateApi, userDetaisl.id);

  const forcastPostSchema = (ids, activitytypeId, forecast, sfid) => {
    return {
      contractids: ids,
      activitytypeid: activitytypeId,
      forecast: forecast,
      sfids: sfid,
    };
  };

  
  

  useImperativeHandle(
    ref,
    () => {
      const foreCastPosSubmission = async () => {
        const reverseParsedObj =
          src?.length &&
          src.map((itm) => {
            return reverseParseFromCofig(itm);
          });
        const DateError = reverseParsedObj.map((itm) => {
          return itm.dates;
        });
        const sngleArr = DateError.flat(1);
        const temp =
          sngleArr?.length > 0 &&
          sngleArr.filter(
            (itm) => itm?.length === 0 || itm.includes("Invalid")
          );
        setSSubmitClicker(true);
        const ids =
          list?.length > 0 &&
          // eslint-disable-next-line array-callback-return
          list.filter((itm) => {
            if (itm.isselected) {
              return itm;
            }
          });
        const idsel =
          foreCastAccounts?.length > 0 &&
          // eslint-disable-next-line array-callback-return
          foreCastAccounts.filter((itm) => {
            if (itm.isselected) {
              return itm;
            }
          });
        // console.log("checjdatafilter",ids)
        const sfids =
          ids?.length > 0
            ? ids.map((itm) => {
                return itm.sfid;
              })
            : [];
        const sfidsel =
          idsel?.length > 0
            ? idsel.map((itm) => {
                return itm.sfid;
              })
            : [];
        const commonsfids = [...sfids, ...sfidsel];


        if (commonsfids?.length >= 0 && temp?.length === 0) {
          setAllowNav(true);
          const getInterface =
            action.type === ACTIONTYPE
              ? [action.contratId]
              : selectedAccountIds;
          const reqBody = await forcastPostSchema(
            getInterface,
            props.id,
            reverseParsedObj,
            [...new Set(commonsfids)]
          );
          dispatch(updateforeCastInfo({ url: postForecastApi, body: reqBody }));
        } else {
          if (temp?.length > 0) {
            popUpSuccessr("please enter all the date fields", "error");
          } else {
            popUpSuccessr("please select at least of variety", "error");
          }
        }
      };

      return {
        submitForeCastInfo() {
          foreCastPosSubmission();
        },
      };
    },
    [src, foreCastAccounts, getAccounts, action, list]
  );

  const ValNxtFind = (srcnxt) => {
    if (srcnxt?.length > 0) {
      let nextdate;
      if (typeof src === "object") {
        nextdate = srcnxt[0]?.value;
      } else {
        nextdate = srcnxt[0];
      }
      return nextdate;
    } else {
      return "";
    }
  };
  const ValPrevFind = (srcprev) => {
    if (srcprev?.length > 0) {
      let prevdate;
      if (typeof src === "object") {
        prevdate = srcprev.pop().value;
      } else {
        prevdate = srcprev.pop();
      }
      return prevdate;
    } else {
      return "";
    }
  };

  const changePrevSel = (indx) => {
    const sourceData = JSON.parse(JSON.stringify(src))
    dispatch(setActivityEnable({ id: props.id, enable: true }));
    dispatch(getOrderManageObj({ status: "INPROGRESS", id: 3 }));
    // [...src]; shallow copy to deep copy
    if(indx === 2){
    setAllowPrev(!allowPrev);
    const PrevyearDetails = JSON.parse(JSON.stringify(src[1] || {}))
    const CurryearDetails = JSON.parse(JSON.stringify(src[2] || {}))
    CurryearDetails['Frequency'] = [...PrevyearDetails.Frequency]
    CurryearDetails['SubmittedBy'] = [...PrevyearDetails.SubmittedBy]
    CurryearDetails['inputDetails'] = [...PrevyearDetails.inputDetails]
    sourceData[2] = {...CurryearDetails}
    setSrc(sourceData);
    dispatch(setForecastSelection(sourceData))
    }else if(indx === 1){
      setAllowNext(!allowNext);
      setAllowPrev(false);
      const PrevyearDetails = JSON.parse(JSON.stringify(src[0] || {}))
      const CurryearDetails = JSON.parse(JSON.stringify(src[1] || {}))
      CurryearDetails['Frequency'] = [...PrevyearDetails.Frequency]
      CurryearDetails['SubmittedBy'] = [...PrevyearDetails.SubmittedBy]
      CurryearDetails['inputDetails'] = [...PrevyearDetails.inputDetails]
      sourceData[1] = {...CurryearDetails}
      setSrc(sourceData); 
      dispatch(setForecastSelection(sourceData))
    }
  };

  const updateDateChange = (year, type, index, ind, value) => {
    const sourceData = JSON.parse(JSON.stringify(src))
    // [...src]; shallow copy to deep copy
    const yearDetails = JSON.parse(JSON.stringify(src[ind] || {}))
    // { ...src[ind] } || {}; shallow copy to deep copy
    const inputDetails = JSON.parse(JSON.stringify(yearDetails[type]))
    // [...yearDetails[type]]; shallow copy to deep copy
    const chertt =
      inputDetails?.length &&
      inputDetails.filter(
        (itm) => itm.length || (typeof itm === "object" && itm?.value?.length)
      );
    const prevv = chertt?.length && chertt.filter((itm) => itm.id < index);
    const nextt = chertt?.length && chertt.filter((itm) => itm.id > index);
    dispatch(setActivityEnable({ id: props.id, enable: true }));
    dispatch(getOrderManageObj({ status: "INPROGRESS", id: 3 }));
    if (index >= 0) {
      const prev = ValPrevFind(prevv);
      const next = ValNxtFind(nextt);
      const current = value;
      const prevDate = `${dayjs(prev).get("year")}/${dayjs(prev).get(
        "month"
      )}/${dayjs(prev).get("date")}`;
      const nextDate =
        next &&
        `${dayjs(next).get("year")}/${dayjs(next).get("month")}/${dayjs(
          next
        ).get("date")}`;
      const currentDate = `${dayjs(current).get("year")}/${dayjs(current).get(
        "month"
      )}/${dayjs(current).get("date")}`;
      const log1 = dayjs(currentDate).diff(prevDate);
      const log2 = dayjs(nextDate).diff(currentDate);
      if (log1 <= 1 || log2 <= 1) {
        if (log1 <= 1) {
          popUpSuccessr(
            "Selected Date is less than the previous Date",
            "error"
          );
          inputDetails[index] = {
            name: "SubmittedBy",
            id: index,
            year: year,
            value: "",
          };
          yearDetails[type] = [...inputDetails];
          sourceData[ind][type] = [...inputDetails];
          setSrc(sourceData);
          setSrc(sourceData);
          const replicaSrc = JSON.parse(JSON.stringify(sourceData)) 
          dispatch(setForecastSelection(replicaSrc))
        } else if (
          log2 <= 1 &&
          inputDetails?.length > index + 1 &&
          nextDate?.length > 0
        ) {
          popUpSuccessr("Selected Date is greater than the next Date", "warning");
          inputDetails[index] = {
            name: "SubmittedBy",
            id: index,
            year: year,
            value: "",
          };
          yearDetails[type] = [...inputDetails];
          sourceData[ind][type] = [...inputDetails];
          setSrc(sourceData);
          const replicaSrc = JSON.parse(JSON.stringify(sourceData)) 
          dispatch(setForecastSelection(replicaSrc))

        } else {
          inputDetails[index] = {
            name: "SubmittedBy",
            id: index,
            year: year,
            value: value,
          };
          yearDetails[type] = [...inputDetails];
          sourceData[ind][type] = [...inputDetails];
          setSrc(sourceData);
          const replicaSrc = JSON.parse(JSON.stringify(sourceData)) 
          dispatch(setForecastSelection(replicaSrc))
          setSSubmitClicker(false);
          dispatch(setActivityEnable({ id: props.id, enable: true }));
        }
      } else {
        inputDetails[index] = {
          name: "SubmittedBy",
          id: index,
          year: year,
          value: value,
        };
        yearDetails[type] = [...inputDetails];
        sourceData[ind][type] = [...inputDetails];
        setSrc(sourceData);
        const replicaSrc = JSON.parse(JSON.stringify(sourceData)) 
        dispatch(setForecastSelection(replicaSrc))
        setSSubmitClicker(false);
      }

      // console.log("prevdates2::",log2 <= 1,log2,log1,log1 <= 1,current,prev,next,dayjs(current).diff(prev),dayjs(next).diff(current))
    } else {
      // console.log("prevdates3::")

      inputDetails[index] = {
        name: "SubmittedBy",
        id: index,
        year: year,
        value: value,
      };
      yearDetails[type] = [...inputDetails];
      sourceData[ind][type] = [...inputDetails];
      setSrc(sourceData);
      if(ind === 2){
        setAllowPrev(false)
      }else if(ind === 1){
        setAllowNext(false);
        setAllowPrev(false)
      }else if(ind === 0){
        setAllowNext(false);
      }
      const replicaSrc = JSON.parse(JSON.stringify(sourceData)) 
      dispatch(setForecastSelection(replicaSrc))
      dispatch(setForecastSelection(sourceData))
      setSSubmitClicker(false);
      dispatch(setActivityEnable({ id: props.id, enable: true }));
    }
  };

  const updateDetails = (year, title, type, item, index) => {
    const sourceData = JSON.parse(JSON.stringify(src))
    // [...src]; shallow copy to deep copy
        
    const yearDetails = JSON.parse(JSON.stringify(src[index]))
    // { ...src[index] } || {}; shallow copy to deep copy
    const inputDetails = JSON.parse(JSON.stringify(yearDetails[type]))
    // [...yearDetails[type]]; shallow copy to deep copy
    dispatch(getOrderManageObj({ status: "INPROGRESS", id: 3 }));
    dispatch(setActivityEnable({ id: props.id, enable: true }));
    // console.log("llll",year, title, type, item, index)
    let updatedType =
      inputDetails?.length > 0 &&
      inputDetails.map((itemType, index) => {
        if (itemType.id === item) {
          return { ...itemType, selected: true };
        } else {
          return { ...itemType, selected: false };
        }
      });
    sourceData[index][type] = [...updatedType];
    if (type === "Frequency") {
      const subDte = yearDetails["SubmittedBy"];
      const sbmtDte = sumitedDatesArrange(year, type, item, subDte);
      sourceData[index]["SubmittedBy"] = [...sbmtDte];
    }
    setSrc(sourceData);
    if(index === 2){
      setAllowPrev(false)
    }else if(index === 1){
      setAllowNext(false);
      setAllowPrev(false)
    }else if(index === 0){
      setAllowNext(false);
    };
    const replicaSrc = JSON.parse(JSON.stringify(sourceData)) 
    dispatch(setForecastSelection(replicaSrc))
  };

  useEffect(() => {
    if(getSelection?.length === 0){  
    const parsedConfig =
      exisingForecast?.length &&
      exisingForecast.map((itm) => {
        return pageConfigSchema(
          itm.year,
          itm.inputdetails,
          itm.frequency,
          itm.dates,
          action.type
        );
      });
    Promise.all(parsedConfig).then((values) => {
      setSrc(values);
    });
}
  }, [exisingForecast]);

  useEffect(() => {
    if(getSelection?.length === 0 ){  
    setLoader(true);
    const getInterface =
      action.type === ACTIONTYPE ? action.contratId : selectedAccountIds;
      // eslint-disable-next-line
    dispatch(getforeCastInfo(getForecastApi + `[${getInterface}]`+`&activitytypeid=${props.id}`));
    }else{
    }
  }, [getForecastApi, action]);

  const handleClick = () => {
    setOpen(!open);
  };

  const getFilterData = (filterDatas) => {
    dispatch(setActivityEnable({ id: props.id, enable: true }));
    dispatch(getForeCastAccSelected(filterDatas.selectedRow));
  };

  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };

  const PreSelectForEditAct = (totalaccounts) => {
    let ActivityData = [];
    ActivityData =
      totalaccounts?.length > 0
        ? totalaccounts.map((itm) => {
            return createData(
              itm.sfid || 0,
              itm.name || "",
              itm.accountname || "",
              itm.country__c || "",
              itm.varietyname || "",
              itm.isselected
            );
          })
        : [];
    return ActivityData;
  };

  const setUpList = async (foreCastInfoDetail) => {
    const updatedList = await PreSelectForEditAct(foreCastInfoDetail);
    setlist(updatedList);
    setShallowList(updatedList)
    initialMatFil(updatedList)

  };

  const initialMatFil = async(source) => {
    const reqMatGroup = await refillObjUniq(source)
    setcountryList(reqMatGroup)
 }

 const refillObjUniq = (source) => {
   var getUniqMarketGroups = source&&source.reduce((unique, o) => {
       if(!unique.some(obj => obj.country === o.country)) {
         unique.push(o);
       }
       return unique;
   },[]);
   return getUniqMarketGroups
 }
 

  useEffect(() => {
    setUpList(foreCastInfoDetails?.list || []);
    const arrangeInOrder =
      (foreCastInfoDetails?.forecast?.length > 0 &&
        foreCastInfoDetails?.forecast) ||
      respFormat;
    const copyBlaz = [...arrangeInOrder] || [];
    const newArrObj =
      copyBlaz &&
      copyBlaz.sort((a, b) => {
        return a.year - b.year;
      });
    setsetexisingForecast(newArrObj);
    
  }, [foreCastInfoDetails]);

  useEffect(() => {
    const sprdList = [...foreCastAccounts,...list]
          var getUniqMarketGroups =  sprdList&&sprdList.reduce((unique, o) => {
            if(!unique.some(obj => obj.sfid === o.sfid)) {
              unique.push(o);
            }
            return unique;
        },[]); 
    setlist(getUniqMarketGroups);
    setShallowList(foreCastAccounts)
    initialMatFil(getUniqMarketGroups)
  }, [foreCastAccounts]);



  useEffect(() => {
    if (foreCastloading === false && !foreCastDetails) {
      const errormsg = foreCastDetails?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = foreCastDetails?.status ? errormsg : noNetworkMessage;
      popUpSuccessr(msg, "error");
      allowNav && dispatch(resetStatus());
    } else if (foreCastDetails?.status === SUCCESS) {
      if (
        action.type === ACTIONTYPE &&
        typeof foreCastDetails.body === "object"
      ) {
        setLoader(false);
      } else {
        if (
          action.type !== ACTIONTYPE &&
          typeof foreCastDetails.body === "object"
        ) {
        }
        setLoader(false);
        popUpSuccessr(foreCastDetails?.message, "success");
        allowNav && props.nextActivity();
        allowNav && dispatch(resetStatus());
        dispatch(setActivityEnable({ id: 3, enable: false }));
        allowNav && dispatch(getOrderManageObj({ status: "DONE", id: 3 }));
      }
    }
  }, [foreCastloading]);

  
  const FilterField =(props)=>{

    const handleChange = (event) => {
      props.selectedValue(event.target.value);
    };
    return(
      <Box elevation={0} sx={{display:'flex'}} style={{flexDirection:'column'}}>
        <Typography style={{paddingLeft:0,display:'flex',alignItem:'center',justifyContent:'left',fontSize:'11px',letterSpacing:0.5}} component='label'>
          {props.title}
          </Typography>
        <FormControl sx={{ mr: 2, minWidth: 170 }} size="small">
      <StyledSelect
      name={props.name}
        labelId={props.name}
        id={props.name}
        value={props.value}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
        style={{width:170,fontSize:11,display:'flex',letterSpacing:0.5}}
        sx={{display:'flex'}}
      >
        <CustomMenu value='all'>{props?.allLabel}</CustomMenu>
       {props.src?.length > 0&&(props.src).map((itm)=>{
          return <CustomMenu value={itm[props.name]}>{itm[props.name]}</CustomMenu>
       }) }
      </StyledSelect>
    </FormControl>
      </Box>
      )
  }

        
 const filAccSelect = async(value)=>{
   if(value === 'all'){
    const filaccbycntry = list&&list.filter(val=>val['country'] === filCountry)
    setShallowList(filaccbycntry)
    setvarietyList([])

    setFilacc(value)
    setFillVariety(value)
   }else{
  const filaccbycntry = list&&list.filter(val=>val['country'] === filCountry)
  const filaccbycntryacc = filaccbycntry&&filaccbycntry.filter(val=>val['accname'] === value)
  setShallowList(filaccbycntryacc)
  var getUniqMarketGroups = filaccbycntryacc&&filaccbycntryacc.reduce((unique, o) => {
      if(!unique.some(obj => obj['varieties'] === o['varieties'])) {
        unique.push(o);
      }
      return unique;
  },[]);
  setvarietyList(getUniqMarketGroups)
  setFilacc(value)
  setFillVariety('all')
}

}
const filCountrySelect =(value)=>{
  if(value === 'all'){
    setShallowList([...list])
    setAccList([])
    setvarietyList([])

    setFillVariety(value)
    setFillCountry(value)
    setFilacc(value)

  }else{
  const filaccbycntry = list&&list.filter(val=>val['country'] === value)
  setShallowList(filaccbycntry)
  var getUniqMarketGroups = filaccbycntry&&filaccbycntry.reduce((unique, o) => {
      if(!unique.some(obj => obj['accname'] === o['accname'])) {
        unique.push(o);
      }
      return unique;
  },[]);
   setAccList(getUniqMarketGroups)
   setFillCountry(value)
   setFilacc('all')
   setFillVariety('all')
}
}
const filVarietySelect =(value)=>{
  if(value === 'all'){
    const filaccbycntry = list&&list.filter(val=>val['country'] === filCountry)
    const filcuntrybyvrty = filaccbycntry&&filaccbycntry.filter(val=>val['accname'] === filacc)
    setShallowList(filcuntrybyvrty)
    setFillVariety(value)
  }else{
  const filaccbycntry = list&&list.filter(val=>val['country'] === filCountry )
  const filcuntrybyvrty = filaccbycntry&&filaccbycntry.filter(val=>val['accname'] === filacc)
  const filvariety = filcuntrybyvrty&&filcuntrybyvrty.filter(val=>val['varieties'] === value)
  setShallowList(filvariety)
  setFillVariety(value)
  }
}


  // console.log("sample999",src)

  return (
    <Fragment>
      {(foreCastloading && !foreCastError) || (loader && <Loader />)}
      <Grid ref={inputRef} container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            height: {
              xl: 430,
              lg: 430,
              overflow: "auto",
            },
          }}
        //   className="test"
        >
          <Item
            elevation={0}
            // sx={{
            //   maxHeight: {
            //     xl: 430,
            //     lg: 430,
            //     overflow: "hidden",
            //   },
            // }}
          >
            {src?.length > 0 &&
              src.map((item, ind) => {
                return (
                  <ForeCastDetails
                    ind ={ind}
                    changePrevSel = {(indx)=>changePrevSel(indx)}
                    selectPrev = {allowPrev}
                    selectNext = {allowNext}
                    allowNav={submitClicker}
                    changeFor={MONTHLY}
                    updateDate={(year, type, index, value) =>
                      updateDateChange(year, type, index, ind, value)
                    }
                    updatedSelection={(year, title, type, value) =>
                      updateDetails(year, title, type, value, ind)
                    }
                    src={item}
                    selectedYear={selectedYear}
                    selectedFreq={selectedFreq}
                  />
                );
              })}
          </Item>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              disableRipple
              onClick={handleClick}
              style={{ padding: 0, backgroundColor: "#EBF0F2", margin: 0 }}
            >
              <Typography
                sx={{ fontSize: 12 }}
                style={{ ...useStyles.ForeCastHeader }}
                component="div"
              >
                List of varieties
              </Typography>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" style={{ paddingTop: 10 }}>
            <Paper style={{display:'flex',width:'100%',height:70,borderRadius:0,paddingTop:10,paddingLeft:10}}>
                <FilterField name={'country'} value={filCountry} selectedValue={(value)=>filCountrySelect(value)}  src={countryList} title='Country' allLabel="All countries"/>
                <FilterField name={'accname'} value={filacc} selectedValue={(value)=>filAccSelect(value)}  src={accList} title='Account name' allLabel="All accounts"/>
                <FilterField name={'varieties'} value={filVariety} selectedValue={(value)=>filVarietySelect(value)}  src={varietyList} title='Variety' allLabel="All varieties"/>
            </Paper>
              <CustomTable
                from="FORECAST"
                statusLoad={false}
                rows={shallowList}
                headCells={headCells}
                getFilterData={(filter) => getFilterData(filter)}
                isPagination={false}
                selectedChecked={foreCastAccounts}
                // getAPICall={getContractsTableList}
                // totalCount={totalCount}
              ></CustomTable>
            </Collapse>
          </List>
        </Grid>
        {/* <Grid item xs={3}>
                <Item elevation={0}>
                    <FilterField value={annualTarget} updatedValue ={(item)=>updateAnnualTaregt(item)} width={'150'} title={ANNUALTARGETTITLE} />
                    <ListOfVarieties />
                </Item>
            </Grid> */}
      </Grid>
    </Fragment>
  );
});

export default memo(ForeCastInfo);
