// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  longTermBusinessSoln: {
    data:{},
    isGetAPICalled:false
  },
  offlineMarketActivities: {
    activities: [],
    rowData:{},
    isGetAPICalled: false,
  },
  onlineMarketActivities: {
    activities: [],
    rowData:{},
    isGetAPICalled: false,
  },
  brandRepresentation: {
    activities: [],
    rowData:{},
    isGetAPICalled: false,
  },
};

// ==============================|| SLICE - Actvities  ||============================== //

const activitiesRedux = createSlice({
  name: "activitiesRedux",
  initialState,
  reducers: {
    getLongTmBsSoln(state, action) {
      state.longTermBusinessSoln = action.payload.longTermBusinessSoln;
    },
    setOfflineMarketingAct(state, action) {
      state.offlineMarketActivities = action.payload.offlineMarketActivities;
    },
    setOnlineMarketingAct(state, action) {
      state.onlineMarketActivities = action.payload.onlineMarketActivities;
    },
    setBrandRepresentation(state, action) {
      state.brandRepresentation = action.payload.brandRepresentation;
    },
    resetActivities(state,action){
      Object.assign(state, initialState)
    }
  },
});

export default activitiesRedux.reducer;

// export const { getLongTmBsSoln,getEditedAccountActivities } = activitiesRedux.actions;
export const {
  getLongTmBsSoln,
  setOfflineMarketingAct,
  setOnlineMarketingAct,
  setBrandRepresentation,
  resetActivities
} = activitiesRedux.actions;
