import React from "react";
import {Navigate } from "react-router-dom";
import Authorization from '../utils/authorization';


export default function PublicRoute({ children }) {
  if (Authorization.isLoggedIn()) {
    // if logged in so redirect to dashboard page with the return url
    return <Navigate to="/dashboard" />;
  }
  // authorized so return child components
  return children;
}
