import React from "react";
import {Navigate, useLocation } from "react-router-dom";
import Authorization from '../utils/authorization';
import { useSelector } from "react-redux";


export default function PrivateRoute({ children,roles }) {
  const authUser= Authorization.getAuthUser();
  const userHasRequiredRole =  authUser && roles?.length>0&& roles?.includes(authUser.userrole) ? true : false;
  const location = useLocation();
  const ContractActionType = useSelector((state) => state.common.ContractActionType);
  
  if (!Authorization.isLoggedIn() ) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }
  if( Authorization.isLoggedIn()&& !userHasRequiredRole){
    return <Navigate to="/dashboard" />;
  }
  if(location.pathname === '/editcontract' &&  ContractActionType && ContractActionType?.type !== 'EDIT') {
    return <Navigate to="/contracts" />;
  }
  
  // authorized so return child components
  return children;
}
