// ==============================|| OVERRIDES - MuiCssBaseline ||============================== //
import HelveticaNeueFont from "../../assets/fonts/HelveticaNeue-Regular.ttf"
export default function MuiCssBaseline(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Helvetica Neue';
              font-style: normal;
              font-display: swap;
              src: local('Helvetica Neue'), url(${HelveticaNeueFont}) format('truetype');
            }
          `,
      }
    };
  }