/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Fragment, useState,useEffect } from "react";
//material-ui
import { CardActionArea, CardContent, FormControl, Grid, Typography } from "@mui/material";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PaymentsIcon from "@mui/icons-material/Payments";
import Authorization from "../../../utils/authorization";
import Loader from "../../../components/ui/Loader"; 
import { activeCountryCode ,getContractActionType,getCountryList} from "../../../store/reducers/common";
import { useSelector,useDispatch } from "react-redux";
import Accounts from "./Accounts";
import Card from '@mui/material/Card';
import MainCard from "../../../components/MainCard";
import TimePerformance from "./TimePerformance";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Link from "@mui/material/Link";
import ReactSelect from "../../../components/third-party/ReactSelect";
import { Link as RouterLink,useNavigate } from 'react-router-dom'
import { apiURL } from "../../../services/apiUrlConfig";
import { getRequest } from "../../../services/apiUtils";
import { openSnackbar } from "../../../components/ui/Toastr";
import {ErrorMsg} from "../../../utils/helper";
import util from 'util'







import './dashboard.scss';


const CardList = [
  {
    title: "Contracts/Targets",
    icon: <ReceiptLongIcon sx={{ color: "black", opacity: 1 }} />,
    avatarSX: { backgroundColor: "#62496324" },
    to: '/contracts'
  },
  {
    title: "Tracking",
    icon: <QueryStatsIcon sx={{ color: "#00617F" }} />,
    avatarSX: { backgroundColor: "#00617f1a" },
  },
  {
    title: "Payout",
    icon: <PaymentsIcon sx={{ color: "#2B6636" }} />,
    avatarSX: { backgroundColor: "#2b663642" },
  },
];


const PPPDashboard = () => {

  const currentYear = new Date().getFullYear();
  const oldDataYear= 2024
  let years=[]
  if(currentYear>oldDataYear){
    years = Array.from({ length: currentYear - oldDataYear+1 }, (_, index) => currentYear - index);
  }else{
    years=[oldDataYear]
  }
 
  const [isLoader, SetLoader] = useState(false);
  const [accountsYear, setYearAccounts] = useState(currentYear);
  const [analyticsYear, setYearAnalytics] = useState(currentYear);
  const { countryCode } = useSelector((state) => state.common);
  const authUser = Authorization.getAuthUser();
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  
  useEffect(()=>{
    getLoadData() 
  },[])

  const getLoadData = () => {
    const { analytics } = apiURL;
    const getApi = analytics.getCountry ?? ''
    const userDetaisl = Authorization.authUser
    const getCountryIntrfce = util.format(getApi, userDetaisl.id)
    SetLoader(true);
    getRequest({
      url: getCountryIntrfce,
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body?.data;
        if (result?.length > 0) {
          let options = [{ value: "ALL", label: "ALL" }];
          const selectOptions = result.map((rst) => {
            let obj = { value: rst?.countrycode, label: rst?.countryname };
            return (obj = { ...obj });
          });
          const updatedOptions= [...options,...selectOptions]
          dispatch(getCountryList({ countryList: result}));
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };


  const callbackLoader = (isLoader) => {
    SetLoader(isLoader);
  };

  const dropDownValues = years.map((year, index) => (
    { value: year, label: year }
  ))

  const handleChange = (option) => {
    setYearAccounts(option?.value);
  }
  const handleChangeAnalytics = (option) => {
    setYearAnalytics(option?.value);
  }
 const navigateToContract = () => {
  dispatch(getContractActionType({type:'CREATE',contratId:'',step:''}))
  navigate("/contracts");
 }
  return (
    <Fragment>
      {isLoader && <Loader />}
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          {/* <Link component={RouterLink} to='/contracts'> */}
            <Card className="top-card-contacts" sx={{ maxWidth: 345, backgroundColor: '#00617f', borderRadius: '10px' }}>
              <CardActionArea onClick={()=>navigateToContract()}>
                <CardContent className="top-card-content">
                  <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <ReceiptLongIcon sx={{ color: "white", opacity: 1, height: '1.5em', width: '1.5em', }} />
                      <Typography variant="body2" style={{ fontSize: '18px', color: 'white', marginTop: '1vh' }}>
                      Contracts/Targets
                      </Typography>
                    </div>
                    <ArrowForwardIosIcon sx={{ color: 'white' }} />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          {/* </Link> */}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card className="top-card-activities" sx={{ maxWidth: 345, backgroundColor: '#2b6636', borderRadius: '10px' }}>
            <CardActionArea>
              <CardContent>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <MonitorHeartOutlinedIcon sx={{ color: "white", opacity: 1, height: '1.5em', width: '1.5em', }} />
                    <Typography variant="body2" style={{ fontSize: '18px', color: 'white', marginTop: '1vh' }}>
                      Activities
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon sx={{ color: 'white' }} />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card className="top-card-tracking" sx={{ maxWidth: 345, backgroundColor: '#624963', borderRadius: '10px' }}>
            <CardActionArea>
              <CardContent>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <QueryStatsIcon sx={{ color: "white", opacity: 1, height: '1.5em', width: '1.5em', }} />
                    <Typography variant="body2" style={{ fontSize: '18px', color: 'white', marginTop: '1vh' }}>
                      Tracking
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon sx={{ color: 'white' }} />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card className="top-card-payout" sx={{ maxWidth: 345, backgroundColor: '#de0043', borderRadius: '10px' }}>
            <CardActionArea>
              <CardContent>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <PaymentsIcon sx={{ color: "white", opacity: 1, height: '1.5em', width: '1.5em', }} />
                    <Typography variant="body2" style={{ fontSize: '18px', color: 'white', marginTop: '1vh' }}>
                      Payout
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon sx={{ color: 'white' }} />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={6}>
          <MainCard
            title=""
            elevation={0}
            border={false}
            content={true}
            contentSX={{ p: 1 }}
            headerSX={{ p: 1, color: "#707070" }}
          >
            <div style={{ display: 'flex', marginTop: '20px', marginLeft: '20px', marginBottom: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Analytics</div>
              <div style={{ maxWidth: '25%' }}>
                <FormControl sx={{ }} className='acc-react-select'>
                  <ReactSelect
                    name={"accounts"}
                    options={dropDownValues}
                    handleChange={(selectedOptions, e) => {
                      handleChangeAnalytics(selectedOptions);
                    }}
                    value={analyticsYear}
                    id="account-list"
                    labelPosition="top"
                    width={100}
                    className="acc-select"
                    defaultValue="List of accounts selected"
                  />
                </FormControl>
              </div>
            </div>
            <TimePerformance
              callbackLoader={callbackLoader}
              selectedCountryCode={countryCode}
              loggedUser={authUser}
            />
          </MainCard>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          {/* <Card sx={{ maxWidth: 600, minHeight:300, backgroundColor: '#de0043', borderRadius: '10px' }}>
            <CardActionArea>
              <CardContent>
                <div>
                  <div style={{ float: 'right', marginBottom: '5vh', marginRight: '1vw' }}>
                    <span>Accounts</span>
                    
                    <FormControl sx={{ minWidth: 300 }} className='acc-react-select'>
                      <ReactSelect
                        name={"accounts"}
                        options={dropDownValues}
                        handleChange={(selectedOptions, e) => {
                          handleChange(selectedOptions);
                        }}
                        value={year}
                        id="account-list"
                        labelPosition="top"
                        width={100}
                        className="acc-select"
                        defaultValue="List of accounts selected"
                      />
                    </FormControl>
                  </div>
                  <Accounts
                    callbackLoader={callbackLoader}
                    selectedCountryCode={countryCode}
                    loggedUser={authUser}
                  />
                </div>
              </CardContent>
            </CardActionArea>
          </Card> */}


          <MainCard
            // title="Accounts"
            elevation={0}
            border={false}
            content={true}
            contentSX={{ p: 1 }}
            headerSX={{ p: 1, color: "#707070" }}
          >
            {/* <div style={{ display: 'flex', marginTop: '20px', marginLeft: '20px', marginBottom: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Accounts</div>
              <div style={{ maxWidth: '25%' }}>
                <FormControl sx={{ minWidth: 300 }} className='acc-react-select'>
                  <ReactSelect
                    name={"accounts"}
                    options={dropDownValues}
                    handleChange={(selectedOptions, e) => {
                      handleChange(selectedOptions);
                    }}
                    value={accountsYear}
                    id="account-list"
                    labelPosition="top"
                    width={100}
                    className="acc-select"
                    defaultValue="List of accounts selected"
                  />
                </FormControl>
              </div>
            </div> */}

            <Grid container spacing={2} sx={{    marginTop: '1.5vh', marginLeft: '20px'}}>
              <Grid item xs={6} sx={{marginBottom: '13vh'}}>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Accounts</div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ maxWidth: '30%', marginLeft:'auto' }}>
                  <FormControl sx={{ }} className='acc-react-select'>
                    <ReactSelect
                      name={"accounts"}
                      options={dropDownValues}
                      handleChange={(selectedOptions, e) => {
                        handleChange(selectedOptions);
                      }}
                      value={accountsYear}
                      id="account-list"
                      labelPosition="top"
                      width={100}
                      className="acc-select"
                      defaultValue="List of accounts selected"
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Accounts
                  callbackLoader={callbackLoader}
                  selectedCountryCode={countryCode}
                  loggedUser={authUser}
                  selectedYear={accountsYear}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PPPDashboard;
