import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";

export default function Loader() {
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 800,backgroundColor: "rgba(0, 0, 0, 0.35) !important" }}
        open={true}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}
