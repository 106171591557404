import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActions
} from "@mui/material";
import SimpleBar from "./third-party/SimpleBar";

//import SimpleBar from 'simplebar-react';

// project import
// import Highlighter from './third-party/Highlighter';

// header style
const headerSXDefault = {
  p: 2.5,
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentSX = {},
      headerSX = {},
      darkTitle,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      codeHighlight,
      isSimpleBar = false,
      scrollHeight,
      avatar,
      isCardAction,
      cardActionChildren,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;
    const condHeaderSX = content ? headerSX : headerSXDefault;
    return (
      <Card
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderRadius: 2,
          height: "100%",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.divider
              : theme.palette.grey.A800,
          boxShadow:
            boxShadow && (!border || theme.palette.mode === "dark")
              ? shadow || theme.customShadows.z1
              : "inherit",
          ":hover": {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : "inherit",
          },
          "& pre": {
            m: 0,
            p: "16px !important",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={condHeaderSX}
            titleTypographyProps={{ variant: "subtitle1" }}
            title={title}
            action={secondary}
            avatar={avatar}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={condHeaderSX}
            title={<Typography variant="h3">{title}</Typography>}
            action={secondary}
            avatar={avatar}
          />
        )}

        {/* card content */}
        {content && !isSimpleBar && (
          <CardContent sx={contentSX}>{children}</CardContent>
        )}
        {!content && children}
        {/* card content scroll bar*/}
        {isSimpleBar && content && (
          <SimpleBar style={{ maxHeight: scrollHeight }}>
            <CardContent sx={contentSX}>{children}</CardContent>{" "}
          </SimpleBar>
        )}
        {isCardAction && <CardActions>{cardActionChildren}</CardActions>}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.node,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  codeHighlight: PropTypes.bool,
  content: PropTypes.bool,
  children: PropTypes.node,
};

export default MainCard;
