import { Fragment, forwardRef } from "react";
import MainCard from "../../components/MainCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso } from "react-virtuoso";

const RenderEmptyPlaceholder = (props) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={props?.headers?.length} style={{ textAlign: "center" }}>
          No Records Found!
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const VirtuosoTableComponents = ( headers) => {
  return {
    Scroller: forwardRef((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    EmptyPlaceholder: (props) => (
      <RenderEmptyPlaceholder {...props}  headers={headers}/>
    ),
  };
};

function fixedHeaderContent(columns) {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{
            backgroundColor: "#388e3c",
            color: "#fff",
            paddingBottom: 1,
            paddingTop: 1,
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row, columns) {
  return (
    <Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align={column.numeric || false ? "right" : "left"}
        >
          {column.dataKey !== "serial" ? row[column.dataKey] : _index + 1}
        </TableCell>
      ))}
    </Fragment>
  );
}

const ReactVirtualizedTable = ({ rows, headers }) => {
  return (
    <TableVirtuoso
      data={rows}
      components={VirtuosoTableComponents(headers)}
      fixedHeaderContent={() => fixedHeaderContent(headers)}
      itemContent={(_index, row) => rowContent(_index, row, headers)}
      style={{ height: 300, width: "100%" }}
    />
  );
};
const LeaderBoardTable = ({ title, rows, headers }) => {
  return (
    <Fragment>
      <MainCard
        title={title}
        elevation={0}
        border={false}
        content={true}
        contentSX={{ p: 0 }}
        headerSX={{ p: 1, color: "#707070" }}
      >
        <ReactVirtualizedTable rows={rows} headers={headers} />
      </MainCard>
    </Fragment>
  );
};
export default LeaderBoardTable;
