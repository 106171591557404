import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    paymentDetails: {},
    paymentdetailsloading: false,
    paymentError: false
}
const userReducer = createSlice({

    name: 'paymentMngmntReducer',
    initialState,
    reducers: {
        
        getPaymentdetails(state) {
            return { ...state, paymentdetailsloading: true };
        },
        getPaymentdetails_successful(state, action) {
            return {
                ...state, paymentDetails: action.payload,
                paymentdetailsloading: false
            }
        },
        getPaymentdetails_error(state, action) {
            return {
                ...state, paymentDetails: action.payload.body,
                paymentError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, paymentdetailsloading: false,
                paymentError: false,
                paymentDetails: {}
            };
        },
    }
})

export const {
    getPaymentdetails,
    getPaymentdetails_successful,
    getPaymentdetails_error,
    resetStatus
} = userReducer.actions;

export default userReducer.reducer