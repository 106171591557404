import * as React from 'react';
import Box from '@mui/material/Box';
import ContractTabs from "../CreateContracts/ContractTabs"

export default function CreateContractTab() {
    return(        
        <Box sx={{width: '100%', typography: 'body1' }} style={{marginRight:10,marginBottom:20,backgroundColor:'white',borderRadius:5}}>
        <ContractTabs/>
        </Box>
      )
}
