import React, { useState, useEffect } from "react";
// import { Chart as ChartJS } from "chart.js/auto";
import { apiURL } from "../../../services/apiUrlConfig";
import { getRequest } from "../../../services/apiUtils";
import { openSnackbar } from "../../../components/ui/Toastr";
import { ErrorMsg } from "../../../utils/helper";
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';


// const data = {
//   labels: [],
//   datasets: [
//     {
//       label: "visitors",
//       data: [],
//       backgroundColor: ["#abed55"],
//       borderColor: ["#abed55"],
//       borderWidth: 1,
//       borderRadius: 20,
//       barThickness: 20,
//     },
//   ],
// };

// const config = {
//   responsive: true,
//   scales: {
//     y: {
//       beginAtZero: true,
//       grace: "50%",
//     },
//   },
//   plugins: {
//     legend: {
//       display: false,
//     },
//   },
// };

const data = {
  labels: [1, 2, 3, 4, 5],
  datasets: [
    {
      label: 'Dataset 2',
      data: [10, 20, 60, 30, 70],
      borderColor: "#EB93AE",
      lineTension: 0.5,
      fill: {
        target: 'origin',
        above: '#fdf0f4a6',   // Area will be red above the origin
        below: 'rgb(0, 0, 255)'    // And blue below the origin
      } 
      // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
    },    {
      label: 'Dataset 1',
      data: [20, 60, 10, 80, 10],
      borderColor: "#79BEE3",
      lineTension: 0.5, 
      fill: {
        target: 'origin',
        above: '#EBF0F2',   // Area will be red above the origin
        below: 'rgb(0, 0, 255)'    // And blue below the origin
      }
      // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
  ]
};

const config = {
  type: 'line',
  data: data,
  options: {
    responsive: true,
  },
  plugins: {
    legend: {
      display: true
    },
  },
  // scales: {
  //   x: {
  //     title: {
  //       display: true,
  //       text: 'Performance' // Name of x-axis
  //     },
  //     beginAtZero: true // Optional
  //   },
  //   y: {
  //     title: {
  //       display: true,
  //       text: 'Time' // Name of y-axis
  //     },
  //     beginAtZero: true // Optional

  //   }
  // }
}

const TimePerformance = ({ callbackLoader, selectedCountryCode, loggedUser }) => {
  const [visitByMthData, setVisitByMthData] = useState(data);

  useEffect(() => {
    getLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode]);
  const getLoadData = () => {
    const { analytics } = apiURL;
    callbackLoader(true);
    getRequest({
      url: analytics.getVisitByMonth,
      queryParams: {
        countryCode: selectedCountryCode === "ALL" ? null : selectedCountryCode,
        userId: loggedUser?.userid,
      },
    })
      .then((response) => {
        callbackLoader(false);
        const result = response?.body?.data;
        if (result) {
          let dataSetsArray = visitByMthData?.datasets[0];
          let labelData = result?.months;
          const updateLabel = labelData?.length > 0 && labelData.map((label) => {
            if (label) {
              const minLetter3 = label.substring(0, 3);
              return (label = minLetter3);
            }
            return label;
          });
          dataSetsArray = { ...dataSetsArray, data: result?.data };
          setVisitByMthData({
            ...visitByMthData,
            labels: updateLabel,
            datasets: [dataSetsArray],
          });
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  return <Line options={config} data={data} />;
};

export default TimePerformance;
