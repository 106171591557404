/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Loader from "../../../../../components/ui/Loader";
import { apiURL } from "../../../../../services/apiUrlConfig";
import {
  getRequest,
  saveOrUpdateRequest,
} from "../../../../../services/apiUtils";
import { openSnackbar } from "../../../../../components/ui/Toastr";
import {
  ErrorMsg,
  submissionCompletionStatus,
} from "../../../../../utils/helper";
import Authorization from "../../../../../utils/authorization";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getLongTmBsSoln } from "../../../../../store/reducers/activities";
import util from "util";
import {
  getOrderManageObj,
  setActivityEnable,
} from "../../../../../store/reducers/contracts";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";


const submitByDateArray = [
  { id: 1, name: "submitbydate1", value: null },
  { id: 2, name: "submitbydate2", value: null },
  { id: 3, name: "submitbydate3", value: null },
  { id: 4, name: "submitbydate4", value: null },
];
const useStyles = {
  Fieldtitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 0,
    marginBottom: 5,
  },
  FieldBase: {
    width: "100%",
    display: "flex",
  },
};

const DateView = (props) => {
  const dateRef = useRef();

  const handleDateChange = (e) => {
    props.updateDate(e.target.value);
  };
  const getValue =
    typeof props?.value === "object" ? props?.value?.value : props?.value;
  return (
    <Box sx={{ pt: 1 }}>
      <Typography
        sx={{ fontSize: 11 }}
        style={{ ...useStyles.Fieldtitle }}
        component="div"
      >
        {props.all?.length > 1
          ? `Submitted by : ${props.id + 1}`
          : "Submitted by"}
      </Typography>
      <Paper elevation={0} style={{ ...useStyles.FieldBase }}>
        <input
          className="datestyle"
          type="date"
          value={getValue}
          onChange={handleDateChange}
          onClick={() => {
            // open the datepicker when click input type date
            if (dateRef?.current) dateRef.current?.showPicker();
          }}
          ref={dateRef}
        ></input>
      </Paper>
    </Box>
  );
};

const LongTermBusinessSolution = forwardRef((props, ref) => {
  const [isLoader, SetLoader] = useState(false);
  const [data, setData] = useState({
    contractids: [],
    activitytypeid: props?.id,
    frequency: "QUARTERLY",
    submitbydate1: null,
    submitbydate2: null,
    submitbydate3: null,
    submitbydate4: null,
  });
  const authUser = Authorization.getAuthUser();
  const [submitByDate, setSubmitByDate] = useState([...submitByDateArray]);
  const { longTermBusinessSoln } = useSelector((state) => state.activities);
  const { accounts } = useSelector((state) => state.contracts);
  const action = useSelector((state) => state.common.ContractActionType);
  const isEditApplicable = action?.type === "EDIT" ? true : false;
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [selectedAccountIds, setSelectedAccountIds] = useState(null);

  useEffect(() => {
    let accountsID = null;
    if (accounts?.length) {
      const arrayIds = accounts.map((acc) => acc?.id);
      accountsID = [...new Set(arrayIds)];
      setSelectedAccountIds(accountsID);
    }
    if (isEditApplicable && !longTermBusinessSoln?.isGetAPICalled) {
      getLoadData(accountsID);
    } else if (Object.keys(longTermBusinessSoln?.data)?.length > 0) {
      setData(longTermBusinessSoln?.data);
      let temp = [...submitByDateArray];
      let obj = { ...longTermBusinessSoln?.data };
      for (let keyA in obj) {
        for (let i = 0; i < temp.length; i++) {
          let name = temp[i].name;
          if (!temp[i].value && name === keyA) {
            temp[i] = { ...temp[i], value: obj[keyA] };
          }
        }
      }
      setSubmitByDate([...temp]);
    }
    dispatch(setActivityEnable({ id: props?.id, enable: true }));
  }, []);

  useEffect(() => {
    dispatch(
      getLongTmBsSoln({
        longTermBusinessSoln: { ...longTermBusinessSoln, data: data },
      })
    );
  }, [data]);

  useImperativeHandle(
    ref,
    () => {
      return {
        submitLongTerm() {
          submitLongTermBusinessSoln();
        },
      };
    },
    [longTermBusinessSoln]
  );

  const validateDateFields = () => {
    const dates = [...submitByDate];
    let isError = false;
    const updatedDates = dates.map((dt) => {
      if (dt.value === null) {
        isError = true;
        return (dt = { ...dt, error: true });
      } else {
        return (dt = { ...dt, error: false });
      }
    });
    setSubmitByDate(updatedDates);
    return isError;
  };

  const submitAPI = (url, method, payload, queryParams, successMsg) => {
    const condUrl = util.format(url, authUser?.id);
    saveOrUpdateRequest({
      url: condUrl,
      method: method,
      data: payload,
      queryParams: queryParams,
    })
      .then((response) => {
        if (response?.status === 200) {
          openSnackbar({ message: successMsg, variant: "success" });
          const completionStatus = submissionCompletionStatus({ payload });
          dispatch(
            getOrderManageObj({ status: completionStatus, id: props.id })
          );
          dispatch(setActivityEnable({ id: props?.id, enable: false }));
          props.nextActivity();
        }
      })
      .catch((error) => {
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  const submitLongTermBusinessSoln = () => {
    const {
      ppp: { activities },
    } = apiURL;
    const url = activities?.createLongTermBusinessSoln;
    let payload = { ...longTermBusinessSoln?.data };
    const isError = validateDateFields();
    payload = {
      ...payload,
      activitytypeid: props?.id,
      contractids: selectedAccountIds,
      frequency:'QUARTERLY'

    };
    if (isError) {
      dispatch(
        getLongTmBsSoln({
          longTermBusinessSoln: { ...longTermBusinessSoln, data: payload },
        })
      );
    } else {
      submitAPI(
        url,
        "POST",
        payload,
        {},
        "Long Term Business Solution is created successfully"
      );
    }
  };

  const getLoadData = (accountIds) => {
    const {
      ppp: { activities },
    } = apiURL;
    SetLoader(true);
    getRequest({
      url: `${activities?.longTermBusinessSoln}/${authUser?.id}/${accountIds}`,
    })
      .then((response) => {
        SetLoader(false);
        let result = response?.body;
        delete result.contractid;
        let obj = {};
        if (Object.keys(result)?.length > 0) {
          for (let key in result) {
            if (result[key] !== null) {
              obj = { ...obj, [key]: result[key] };
            }
          }
        }
        dispatch(
          getLongTmBsSoln({
            longTermBusinessSoln: {
              ...longTermBusinessSoln,
              isGetAPICalled: true,
            },
          })
        );
        setData(Object.keys(obj)?.length > 0 ? obj : {});
        if (Object.keys(obj)?.length > 0) {
          const arrayDate = submitByDate;
          const arrayResult = [obj];
          const updatedResult = arrayDate.map((ad) => {
            arrayResult.map((ar) => {
              return (ad = { ...ad, value: ar[ad.name] });
            });
            return ad;
          });
          setSubmitByDate(updatedResult);
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  const dateViewSetValidations = (dateValue, selObj, index) => {
    const chertt =
      submitByDate?.length && submitByDate.filter((itm) => itm?.value?.length);
    const prevv = chertt?.length && chertt.filter((itm) => itm.id < selObj.id);
    const nextt = chertt?.length && chertt.filter((itm) => itm.id > selObj.id);
    const prev = prevv?.length > 0 ? prevv.pop().value : null;
    const next = nextt?.length > 0 ? nextt[0].value : null;
    const current = dateValue;
    if (index >= 0) {
      const prevDate =
        prev &&
        `${dayjs(prev).get("year")}/${dayjs(prev).get("month")}/${dayjs(
          prev
        ).get("date")}`;
      const nextDate =
        next &&
        `${dayjs(next).get("year")}/${dayjs(next).get("month")}/${dayjs(
          next
        ).get("date")}`;
      const currentDate = `${dayjs(current).get("year")}/${dayjs(current).get(
        "month"
      )}/${dayjs(current).get("date")}`;
      const log1 = prevDate ? dayjs(currentDate).diff(prevDate) : 0;
      const log2 = nextDate ? dayjs(nextDate).diff(currentDate) : 0;
      if (log1 <= 1 && prev != null) {
        openSnackbar({
          message: "Selected date is less than previous date",
          variant: "error",
        });
        handleDateChange(null, { ...submitByDateArray[index] }, index);
      } else if (log2 <= 1 && next != null) {
        openSnackbar({
          message: "Selected date is greater than next date",
          variant: "error",
        });
        handleDateChange(null, { ...submitByDateArray[index] }, index - 1);
      } else {
        handleDateChange(dateValue, selObj, index);
      }
    } else {
      handleDateChange(dateValue, selObj, index);
    }
  };

  const handleDateChange = (dateValue, selObj, index) => {
    console.log("sjjsjsjsj::", dateValue, selObj, index);
    const temp = [...submitByDate];
    temp[index] = {
      ...selObj,
      value: dayjs(dateValue).format("YYYY-MM-DD"),
      error: dateValue ? false : true,
    };
    setSubmitByDate([...temp]);
    let obj = { ...data };
    if (temp?.length > 0) {
      temp.forEach((t) => {
        obj = { ...obj, [t?.name]: t.value };
      });
    }
    setData({ ...obj });
    const completionStatus = submissionCompletionStatus({});
    dispatch(getOrderManageObj({ status: completionStatus, id: props.id }));
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }} ref={inputRef}>
      {isLoader && <Loader />}
      <Typography pb={2} variant="body2">
        Proactive Contributor to the Strategic business planing process
      </Typography>
      <Typography pb={2} variant="body2">
        Partnership Check-ins frequency per calender year
      </Typography>
      <Box display="flex" gap={2} mb={2}>
        <Box display="flex" flexDirection="column" alignItems="baseline">
          <Typography mb={0.4} variant="body2">
            Frequency
          </Typography>
          <Button
            component="label"
            variant="outlined"
            startIcon={<CheckOutlinedIcon />}
          >
            {"Quarterly"}
          </Button>
        </Box>
      </Box>
      <Box display="flex" gap={4}>
        {submitByDate?.length > 0 &&
          submitByDate.map((item, itemIndex) => {
            return (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="baseline"
                key={itemIndex}
              >
                <DateView
                  all={submitByDate}
                  id={itemIndex}
                  updateDate={(value) =>
                    dateViewSetValidations(value, item, itemIndex)
                  }
                  value={item?.value || ""}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
});

export default LongTermBusinessSolution;
