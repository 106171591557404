import { useState, useMemo } from "react";

const useSortData = (products, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortMethod = (firstKey, secondKey, sortConfig) => {
    if (firstKey < secondKey) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (firstKey > secondKey) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  };
  const sortedItems = useMemo(() => {
    const sortedProducts = [...products];
    if (sortConfig !== null) {
      sortedProducts.sort((a, b) => {
        // if (a[sortConfig.key] < b[sortConfig.key]) {
        //   return sortConfig.direction === "ascending" ? -1 : 1;
        // }
        // if (a[sortConfig.key] > b[sortConfig.key]) {
        //   return sortConfig.direction === "ascending" ? 1 : -1;
        // }
        
        //Check if typeof number
        if (Number(a[sortConfig.key]) && Number(b[sortConfig.key])) {
          let firstKey = Number(a[sortConfig.key]);
          let secondKey = Number(b[sortConfig.key]);
          return sortMethod(firstKey, secondKey, sortConfig);
        } else if (
          typeof a[sortConfig.key] === "string" &&
          typeof b[sortConfig.key] === "string"
        ) {
          let firstKey = a[sortConfig.key].toLowerCase();
          let secondKey = b[sortConfig.key].toLowerCase();
          return sortMethod(firstKey, secondKey, sortConfig);
        } else {
          let firstKey = a[sortConfig.key];
          let secondKey = b[sortConfig.key];
          return sortMethod(firstKey, secondKey, sortConfig);
        }
        // return 0;
      });
    }
    return sortedProducts;
  }, [products, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { sortedList: sortedItems, requestSort, sortConfig };
};

export default useSortData;
