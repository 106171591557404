import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    salesVolumeDetails: {},
    salesVolumeloading: false,
    salesVolumeError: false,
    SALES: {}
}
const userReducer = createSlice({

    name: 'salesVolumeReducer',
    initialState,
    reducers: {
        setSalesVolumeActivityData(state, action) {
            state.SALES = {...action.payload}
        },
        getSalesVolume(state) {
            return { ...state, salesVolumeloading: true };
        },
        updateSalesVolume(state) {
            return { ...state, salesVolumeloading: true };
        },
        getSalesVolume_successful(state, action) {
            return {
                ...state, salesVolumeDetails: action.payload,
                salesVolumeloading: false
            }
        },
        getSalesVolume_error(state, action) {
            return {
                ...state, salesVolumeDetails: action.payload.body,
                salesVolumeError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, salesVolumeloading: false,
                salesVolumeError: false,
                salesVolumeDetails: {}
            };
        },
    }
})
export const {
    getSalesVolume,
    getSalesVolume_successful,
    getSalesVolume_error,
    resetStatus,
    updateSalesVolume,
    setSalesVolumeActivityData
} = userReducer.actions;

export default userReducer.reducer