import * as React from "react";
import Box from "@mui/material/Box";
import Logo from "../components/Logo";
export default function FlashScreen() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Logo width={75} height={75} style={{ borderRadius: 5 }} />
    </Box>
  );
}
