import {  createSlice } from '@reduxjs/toolkit'

const initialState = {
    demandGeneration: {},
    demandGenerationloading: false,
    demandGenerationError: false
}
const userReducer = createSlice({ 

    name: 'demandGenerationReducer',
    initialState,
    reducers:{
        getDemandGeneration(state){
            return { ...state, demandGenerationloading: true };
        },
        getDemandGeneration_successful(state, action) {
            return {
                ...state, demandGeneration: action.payload,
                demandGenerationloading: false
            }
        },
        getDemandGeneration_error(state, action) {
            return {
            ...state, demandGeneration: action.payload,
            demandGenerationError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, demandGenerationloading: false,
                demandGenerationError: false,
                demandGeneration: {}
            }
        }
    }
})

export const {getDemandGeneration, getDemandGeneration_successful, getDemandGeneration_error, resetStatus} = userReducer.actions
export default userReducer.reducer