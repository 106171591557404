import * as React from 'react';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { styled,createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Typography from "@mui/material/Typography";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#E0E3E7',
            '--TextField-brandBorderHoverColor': '#B2BAC2',
            '--TextField-brandBorderFocusedColor': 'rgba(0, 0, 0, 0.08)',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    },
  });

  const StyledTextfield = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-inputSizeSmall':{
     height:18,
     fontSize:11.5,
     
   },
   '& label.Mui-focused': {
    color: 'var(--TextField-brandBorderFocusedColor)',
    },
   '& input:valid + fieldset': {
    borderColor: '#E0E3E7',
    borderWidth: 1,
     },
   }));

   export default function MDTextField(props) {
    const outerTheme = useTheme();
    const [value] = React.useState(props.value||'');
    const handleChange = (event) => {
        props.updatedValue(event.target.value)
    }
    return (
      <Box elevation={0} sx={{display:'flex'}} style={{flexDirection:'column',height:60}}>
        {props.title&&<Typography style={{paddingLeft:0,display:'flex',alignItem:'center',justifyContent:'left',fontSize:'11px',fontWeight:500}} component='label'>
          {props.title}
          </Typography>}
          <ThemeProvider theme={customTheme(outerTheme)}>
         <StyledTextfield
        labelId="md-select-small-label"
        id="md-select-small"
        value={props.value||''}
        onChange={handleChange}
        disabled={props.enable?true:false}
        inputProps={{ 'aria-label': 'Without label' }}
        style={{width:props.width,marginTop:5,height:45,fontSize:12,display:'flex'}}
        sx={{minWidth:props.width,display:'flex'
            ,'& .MuiOutlinedInput-root': {
              border:props.Error&&(value||value.length<1)?'1px solid red':'',
            },
           }}
        size='small'
      >
      </StyledTextfield>
      </ThemeProvider>
      </Box>
    )
   }
