// Configuration of API URL
export const apiURL = {
    login: "login/loginweb",
    azureToken:"login/getAzureToken",
    analytics:{
        getMonth:"analytics/getMonthWiseAnalytics",
        getMVM:"analytics/getMostVisitedModules",
        getCurrentByMonthUser:"analytics/userStats",
        getVisitByMonth:"analytics/getVisitorByMonth",
        getCountry:"analytics/getCountries/%s"
    },
    trials:{
        getLocation:"activities/getTrailsWebMapView",
        getAssessment:"activities/getAssessmentsByTraialId"
    },
    targetDashboard: {
      getTrialLeaderBoard: "analytics/trialLeaderBoard",
      getUserLeaderBoard: "analytics/userLeaderBoard",
      getUserTrialLeaderBoard: "analytics/userTrialLeaderBoard",
    },
    otp:{
        sendOtp:"login/sendCode",
        verifyOtp:"login/verifyCode"
    },
    ppp:{
        getContracts:"ppp/get_contracts",
        getActivities:"ppp/activity_types/%s",
        createContract:"ppp/create_contract/%s",
        updateOrderPayment:"PPP/create_order_payment/%s",
        updateSalesVolume:"PPP/create_sales_volume/%s",
        getOrderPayment:"PPP/order_payment/%s/%s",
        getSalesVolume:'PPP/sales_volume/%s/%s',
        getCreatedContracts:'PPP/get_contracts/%s?status=CREATED',
        getForeCastInfo:"ppp/forecast/%s?contractids=",
        updateForeCastInfo:"ppp/create_forecast/%s",
        getMarketIntekkigence:"ppp/market_intelligence/%s?contractids=",
        updateMarketIntelligence:"PPP/create_market_intelligence/%s",
        activities:{
        longTermBusinessSoln:"ppp/long_term_bsolution",
        createLongTermBusinessSoln:"ppp/create_long_term_bsolution/%s",
        getMarketingActivityType:"ppp/marketing_activity_types",
        createOfflineMarket:"ppp/create_marketing_activity/%s",
        getOneMarketingActivities:"ppp/marketing_activities/%s/%s/%s",
        },
        demandgeneration:"ppp/dg_trials/%s?contractids=",
        createDocument:"ppp/update_additionalfields/%s",
        uploadDocument:'file/add_file/%s?contractId=%s&requestType=CONTRACT_CREATE',
        getDashboardStatus:"ppp/get_contract_status",
        getDocument:'file/get_file/%s?fileKey=',
        saveDocument:'ppp/create_contract/%s',
    }
  };
