import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    createDocumentDetails: {},
    createDocumentloading: false,
    createDocumentError: false,
    createDocument: []
}
const userReducer = createSlice({

    name: 'marketIntelligenceReducer',
    initialState,
    reducers: {
        setcreateDocumentData(state, action) {
            console.log("resprespresp",action.payload)
            state.createDocument = [...action.payload]
        },
        getcreateDocument(state) {
            return { ...state, createDocumentloading: true };
        },
        updatecreateDocument(state) {
            return { ...state, createDocumentloading: true };
        },
        getcreateDocument_successful(state, action) {
            return {
                ...state, createDocumentDetails: action.payload,
                createDocumentloading: false
            }
        },
        getcreateDocument_error(state, action) {
            return {
                ...state, createDocumentDetails: action.payload,
                createDocumentError: true,createDocumentloading: false

            }
        },
        resetStatus(state) {
            return {
                ...state, createDocumentloading: false,
                createDocumentError: false,
                createDocumentDetails: {}
            };
        },
    }
})
export const {
    setcreateDocumentData,
    getcreateDocument,
    updatecreateDocument,
    getcreateDocument_successful,
    getcreateDocument_error,
    resetStatus
} = userReducer.actions;

export default userReducer.reducer