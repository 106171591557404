import * as React from 'react';
import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
// import AnalyticsIcon from '@mui/icons-material/Analytics';
import CreateContracts from './CreateContract'
// import TrackChangesIcon from '@mui/icons-material/TrackChanges';
// import TargetSales from './TargetSales'
// const PYSALES = 'PY sales ($)    ';
// const TARGETSALES = 'Target Sales ';

// const DesignSchema =[
//             {img:<AnalyticsIcon style={{width:12,height:12,marginTop:6,marginRight:2}}/>,label:PYSALES,name:'3,33,956',value:'1',component:<CreateContracts/>},
//             // {img:<TrackChangesIcon style={{width:12,height:12,marginTop:6,marginRight:2}}/>,label:TARGETSALES,name:'4,567,534',value:'2',component:<TargetSales/>},
//          ]


export default function CreateContractTabs(props) {
  // eslint-disable-next-line
  const [value, setValue] = React.useState('1');

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <Box sx={{width: '100%', typography: 'body1',height:'400' }} style={{marginRight:5,paddingTop:20,paddingRight:20,paddingLeft:20,marginBottom:20,backgroundColor:'white',borderRadius:5}}>
      <CreateContracts/>
      {/* <TabContext textColor='#0091DF' value={value}>
        <Box sx={{ ml:2.5,mr:2.5,borderBottom: 1, borderColor: 'divider' }}>
          <TabList  style={{height:40}}  onChange={handleChange} aria-label="lab API tabs example">
            {DesignSchema.map((item)=>{
                return(
                <Tab style={{fontWeight:600,display:'flex',flexDirection:'row',fontSize:11,textTransform:'none'}} icon={item.img}  label={<p style={{fontSize:12,display:'flex'}}><p>{item.label}</p><p style={{backgroundColor:'#EBF0F2',marginLeft:5}}>{item.name}</p></p>} value={item.value} />
                )})}
          </TabList>
        </Box>
        {DesignSchema.map((item)=>{
                return(
        <TabPanel sx={{pl:2,pr:2,pt:2}} style={{backgroundColor:'transparent'}} value={item.value}>{item.component}</TabPanel>
        )})}
      </TabContext> */}
    </Box>
  );
}