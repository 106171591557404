import Authorization from "../utils/authorization";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
// import { useTheme } from "@mui/material/styles";
import { Box, Toolbar } from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
// import navigation from "./Drawer/DrawerContent/MenuList";
// import Breadcrumbs from "../components/@extended/Breadcrumbs";

// types
import { openDrawer } from "../store/reducers/menu";
import { confirmationNaviBlocker } from "../store/reducers/common";
import ConfirmationNavigation from "../components/ui/ConfirmNavigation";


// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  // const theme = useTheme();
  // const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  // useEffect(() => {
  //   setOpen(!matchDownLG);
  //   dispatch(openDrawer({ drawerOpen: !matchDownLG }));

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } ,backgroundColor:"#EBF0F2"}}
      >
        <Toolbar />
        {/* <Breadcrumbs navigation={navigation} title /> */}
        
        <Outlet />
      </Box>
    </Box>
  );
};

function Layout(props) {
  const dispatch = useDispatch();
  const { naviBlocker } = useSelector((state) => state.common);  
 const [checkData, setCheckData] = useState(false);
 const dataEmptyHandler = () => {
    setCheckData(false)
    dispatch(confirmationNaviBlocker(false));    
 }

useEffect(() => {
  if(naviBlocker.naviBlock && checkData !== naviBlocker.naviBlock) {
    setCheckData(naviBlocker.naviBlock);   
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [naviBlocker.naviBlock]);


  return Authorization.isLoggedIn ? (
    <div className="body-wrapper">
      <div className="main">
        <MainLayout />
        <ConfirmationNavigation  checkData={checkData}  dataEmptyHandler = {dataEmptyHandler}/>
      </div>
    </div>
  ) : (
    props.children
  );
}
export default Layout;
