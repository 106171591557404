import {put, takeLatest,all  } from "redux-saga/effects";
import {
    getActivities,
    getActivities_successful,
    getActivities_error,
} from './saga/activitiesSaga'
import {
    createContracts,
    createContracts_successful,
    createContracts_error
} from './saga/contractSaga'
import {
    getOrderDetails,
    updateOrderdetails,
    getOrderdetails_successful,
    getOrderdetails_error,
    setActivityData,
    setPaymentActivityData
} from './saga/orderMangeSaga'
import {
    updateSalesVolume,
    getSalesVolume_successful,
    getSalesVolume_error,
    getSalesVolume,
    setSalesVolumeActivityData
} from './saga/salesVolumeSaga'
import {
    getPaymentdetails,
    getPaymentdetails_successful,
    getPaymentdetails_error
} from './saga/paymentManageSaga'
import {
    getContracts,
    getContracts_successful,
    getContracts_error
} from './saga/createdContractSaga'
import {
    setforeCastActivityData,
    getforeCastInfo,
    updateforeCastInfo,
    getforeCastInfo_successful,
    getforeCastInfo_error,

} from './saga/foreCastSaga'
import {
    getmarketIntelligence,
    getmarketIntelligence_successful,
    getmarketIntelligence_error,
    updatemarketIntelligence,
    setmarketIntelligenceActivityData
} from './saga/MarketIntelligenceSaga'
import {
    updatecreateDocument,
    getcreateDocument_successful,
    getcreateDocument_error,
} from './saga/createDocumentSaga'
import { getRequest,saveOrUpdateRequest } from "../services/apiUtils"; 

import { getDemandGeneration, 
    getDemandGeneration_successful, 
    getDemandGeneration_error
 } from './saga/demandGenerationSaga'
 import {
    getDocumentData,
    getDocument_successful,
    getDocument_error,
     } from './saga/documentUpload'
import {
    saveDocumentData,
    saveDocument_successful,
    saveDocument_error,
    } from './saga/documentSave'
    

const commonGetService = async (url)=>{
    const res = await getRequest({
        url: url,
      })
    return res
}

const commonPostService = async(src)=>{
    const res = await saveOrUpdateRequest({
        method: "POST",
        url: src.url,
        data:src.body
      })
    return res
}
function *sagaService() {
    yield all([
        takeLatest(getActivities, fetchActivities),
        takeLatest(createContracts, contractCreation),
        takeLatest(updateOrderdetails, submitOrderDetails),
        takeLatest(updateSalesVolume, submitSalesVolume),
        takeLatest(getPaymentdetails, submitPaymentDetails),
        takeLatest(getOrderDetails, fetchOrderDetails),
        takeLatest(getSalesVolume, fetchSalesVolume),
        takeLatest(getContracts, fetchCreatedActivities),
        takeLatest(getforeCastInfo, fetchForeCastInfo),
        takeLatest(updateforeCastInfo, submitForeCastInfo),
        takeLatest(getmarketIntelligence, fetchMarketIng),
        takeLatest(updatemarketIntelligence, updateMarketIng),
        takeLatest(getDemandGeneration, submitDemandGeneration),
        takeLatest(updatecreateDocument, updateDocumentDetails),
        takeLatest(getDocumentData, documentUpload),
        takeLatest(saveDocumentData, documentSave)

    ]);
  }
  function* documentUpload(actions){
    try{
    const resp = yield commonGetService(actions.payload)
    yield put(getDocument_successful(resp))
    }catch (e){
    yield put(getDocument_error(e))
    }
 }
 function* documentSave(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(saveDocument_successful(resp))
    }catch (e){
    yield put(saveDocument_error(e))
    }
 }
  function* updateMarketIng(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(getmarketIntelligence_successful(resp))
    }catch (e){
    yield put(getmarketIntelligence_error(e))
    }
}
function* updateDocumentDetails(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(getcreateDocument_successful(resp))
    }catch (e){
    yield put(getcreateDocument_error(e))
    }
}

function* fetchMarketIng(actions){
    try{
    const resp = yield commonGetService(actions.payload)
    const responsebody = resp?.body
    const listOfMi = responsebody?.list || []
    yield all([
    yield put(getmarketIntelligence_successful(resp)),
    yield put(setmarketIntelligenceActivityData(listOfMi))
    ])
    }catch (e){
    yield put(getmarketIntelligence_error(e))
    }
}


function* submitForeCastInfo(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(getforeCastInfo_successful(resp))
    }catch (e){
    yield put(getforeCastInfo_error(e))
    }
}

  function* fetchForeCastInfo(actions){
    try{
    const resp = yield commonGetService(actions.payload)
    const responsebody = resp?.body
    const listOfVarieties = responsebody?.list || []
    const foreCast = responsebody?.forecasts || []

    yield all([
    yield put(setforeCastActivityData({list : listOfVarieties, forecast : foreCast})),
    yield put(getforeCastInfo_successful(resp))
    ])
    }catch (e){
    yield put(getforeCastInfo_error(e))
    }
}

 function* fetchActivities(actions){
     try{
     const resp = yield commonGetService(actions.payload)
     yield put(getActivities_successful(resp))
     }catch (e){
     yield put(getActivities_error(e))
     }
 }
 function* fetchCreatedActivities(actions){
    try{
    const resp = yield commonGetService(actions.payload)
    yield put(getContracts_successful(resp))
    }catch (e){
    yield put(getContracts_error(e))
    }
}

 function* contractCreation(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(createContracts_successful(resp))
    }catch (e){
    yield put(createContracts_error(e))
    }
}
function* submitSalesVolume(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(getSalesVolume_successful(resp))
    }catch (e){
    yield put(getSalesVolume_error(e))
    }
}
function* submitOrderDetails(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(getOrderdetails_successful(resp))
    }catch (e){
    yield put(getOrderdetails_error(e))
    }
}
function* fetchSalesVolume(actions){
    try{
    const resp = yield commonGetService(actions.payload)
    const responsebody = resp?.body
    const respObj = {
        'Basic':responsebody?.base,
        'Silver':responsebody?.silver,
        'Gold':responsebody?.gold,
        'Platinum':responsebody?.platinum
    }
    const submitBy = {...respObj}

    yield all([
    put(setSalesVolumeActivityData(submitBy)),
    put(getSalesVolume_successful(resp))
    ])

    }catch (e){
    yield put(getSalesVolume_error(e))
    }
}
function* fetchOrderDetails(actions){
    try{
    const resp = yield commonGetService(actions.payload.url)
    const responsebody = resp?.body
    if(actions.payload.isFor === 'ORDER'){
        const orderValue = responsebody?.order
        const submitBy = orderValue?.submitby
    
        yield all([
            put(setActivityData(submitBy)),
            put(getOrderdetails_successful(resp))
            ])
    }else {
        const paymentValue = responsebody?.payment
        const submitBy = paymentValue?.submitby
    
        yield all([
            put(setPaymentActivityData(submitBy)),
            put(getOrderdetails_successful(resp))
            ])
    }
    
    }catch (e){
    yield put(getOrderdetails_error(e))
    }
}
function* submitPaymentDetails(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(getPaymentdetails_successful(resp))
    }catch (e){
    yield put(getPaymentdetails_error(e))
    }
}
function* submitDemandGeneration(actions){
    try{
    const resp = yield commonPostService(actions.payload)
    yield put(getDemandGeneration_successful(resp))
    }catch (e){
    yield put(getDemandGeneration_error(e))
    }
}
 export default sagaService