/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ReactSelect from "../../../components/third-party/ReactSelect";
import Box from "@mui/material/Box";
import { activeCountryCode ,getCountryList} from "../../../store/reducers/common";
import { useDispatch } from "react-redux";
import { apiURL } from "../../../services/apiUrlConfig";
import { getRequest } from "../../../services/apiUtils";
import { openSnackbar } from "../../../components/ui/Toastr";
import { ErrorMsg } from "../../../utils/helper";
import Loader from "../../../components/ui/Loader";
import { useSelector } from "react-redux";
import Authorization from "../../../utils/authorization";
import util from 'util'


// const options = [
//   { value: "US", label: "USA" },
//   { value: "IN", label: "India" },
// ];
const CountrySelect = () => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("ALL");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isLoader, SetLoader] = useState(false);
  const { countryCode } = useSelector((state) => state.common);
  // eslint-disable-next-line no-unused-vars
  const authUser = Authorization.getAuthUser();

  const handleSelect = (option) => {
    dispatch(activeCountryCode({ countryCode: option?.value }));
    setSelectedValue(option?.value);
  };
  useEffect(() => {
    getLoadData();
  }, []);

  useEffect(() => {
    if (countryCode) setSelectedValue(countryCode) ;
  }, [countryCode]);

  const getLoadData = () => {
    const { analytics } = apiURL;
    const getApi = analytics.getCountry ?? ''
    const userDetaisl = Authorization.authUser
    const getCountryIntrfce = util.format(getApi, userDetaisl.id)
    SetLoader(true);
    getRequest({
      url: getCountryIntrfce,
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body?.data;
        if (result?.length > 0) {
          let options = [{ value: "ALL", label: "All country" }];
          const selectOptions = result.map((rst) => {
            let obj = { value: rst?.countrycode, label: rst?.countryname };
            return (obj = { ...obj });
          });
          const updatedOptions= [...options,...selectOptions]
          setDropdownOptions(updatedOptions);
          dispatch(getCountryList({ countryList: result}));
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  return (
    <Box>
      {isLoader && <Loader />}
      <ReactSelect
        name={"country"}
        options={dropdownOptions}
        handleChange={(selectedOptions, e) => {
          handleSelect(selectedOptions);
        }}
        value={selectedValue}
        id="country-test"
        label="Country"
        labelPosition="left"
        width={140}
        defaultValue="ALL"
      />
    </Box>
  );
};
export default CountrySelect;
