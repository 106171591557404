import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const Accordions = (props) => {
  const { accordionSX,titleName,handleChange,expanded,children } = props;
  return (
    <Accordion
      sx={accordionSX}
      onChange={handleChange}
      expanded={expanded === titleName}
      slots={{ transition: Fade }}
      slotprops={{ transition: { timeout: 400 ,unmountOnExit: true} }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="subtitle1" color="#212121" fontWeight={500}>
          {titleName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px 16px 8px" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default Accordions;
