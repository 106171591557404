/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  Fragment,
} from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/ui/Loader";
import { 
  resetStatus,
  updateOrderdetails,
  getOrderDetails,
  setActivityData,
} from "../../../../../saga/saga/orderMangeSaga";
import { openSnackbar } from "../../../../../components/ui/Toastr";
import util from "util";
import Authorization from "../../../../../utils/authorization";
import { apiURL } from "../../../../../services/apiUrlConfig";
import { submissionCompletionStatus } from "../../../../../utils/helper";
import {
  getOrderManageObj,
  setActivityEnable,
} from "../../../../../store/reducers/contracts";

const SUCCESS = 200;
const ACTION_TYPE = "EDIT";
const ERROR_MSG = "Please check internet or try again!";

const OrderSchema = (ids, activitytypeId, days) => {
  return {
    contractids: ids,
    activitytypeid: activitytypeId,
    submitby: days,
  };
};

const orderRange = { min: 2, max: 6 };
const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, index) => start + index);
};

const SelectionAccounnts = (props) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 50 }}>
      <Select
        value={props.value}
        onChange={(e) => props.valueChange(e)}
        style={{
          width: 140,
          height: 40,
          fontSize: 12,
          backgroundColor: "white",
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {props.orderRangeList?.length > 0 &&
          props.orderRangeList.map((itm, index) => {
            return (
              <MenuItem sx={{ fontSize: 11 }} value={itm}>
                {itm === "loading..." ? "loading..." : itm + " months"}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

const OrderManagement = forwardRef((props, ref) => {
  const temporaryOrderValue = useSelector(
    (state) => state.orderMangeSaga.ORDER
  );
  const [allowNav, setAllowNav] = useState(false);
  const [orderValue, setOrderValue] = useState(
    Number(temporaryOrderValue) || "loading..."
  );
  const orderRangeList =
    orderValue === "loading..."
      ? ["loading..."]
      : range(orderRange.min, orderRange.max);
  const action = useSelector((state) => state.common.ContractActionType);
  const getAccounts = useSelector((state) => state.contracts.accounts);
  const orderSubmitStatus = useSelector(
    (state) => state.orderMangeSaga.orderdetailsloading
  );
  const orderSubmitted = useSelector(
    (state) => state.orderMangeSaga.orderManagementDetails
  ); 
  const orderSubmitError = useSelector(
    (state) => state.orderMangeSaga.orderdetailsError
  );
  const selectedAccountIds =
    getAccounts &&
    getAccounts.map((account) => {
      return account.id;
    });
  const getInterface =
    action.type === ACTION_TYPE ? [action.contratId] : selectedAccountIds;
  const reqBody = OrderSchema(getInterface, props.id, orderValue);
  const updateApi = apiURL.ppp.updateOrderPayment ?? "";
  const userDetaisl = Authorization.authUser;
  const createContractApi = util.format(updateApi, userDetaisl.id);

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {}, [temporaryOrderValue]);
 

  useImperativeHandle(
    ref,
    () => {
      return {
        submitOrderDetails() {
          dispatch(
            updateOrderdetails({ url: createContractApi, body: reqBody })
          );
          setAllowNav(true);
        }, 
      };
    },
    [orderValue]
  );
  
  const valueChange = async (e) => {
    setOrderValue(e.target?.value);
    dispatch(setActivityEnable({ id: 1, enable: true }));
    const completionStatus = submissionCompletionStatus({});
    dispatch(getOrderManageObj({ status: completionStatus, id: 1 }));
    dispatch(setActivityData(e.target?.value));
  };

  useEffect(() => {
    if (!temporaryOrderValue) {
      if (action.type === ACTION_TYPE) {
        setOrderValue("loading...");
        const getApi = apiURL.ppp.getOrderPayment ?? "";
        const createGetApi = util.format(
          getApi,
          userDetaisl.id,
          action.contratId
        );
        dispatch(getOrderDetails({ url: createGetApi, isFor: "ORDER" }));
      } else {
        setOrderValue(2);
      }
    }
  }, [action, temporaryOrderValue]);

  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };

  useEffect(() => {
    if (orderSubmitStatus === false && !orderSubmitted) {
      const errormsg = orderSubmitted?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = orderSubmitted?.status ? errormsg : noNetworkMessage;
      popUpSuccessr(msg, "error");
      dispatch(resetStatus());
    } else if (orderSubmitted?.status === SUCCESS) {
      if (
        action.type === ACTION_TYPE &&
        typeof orderSubmitted.body === "object"
      ) {
        setOrderValue(temporaryOrderValue || 2);
        dispatch(resetStatus());
      } else {
        if (
          action.type !== ACTION_TYPE &&
          typeof orderSubmitted.body === "object"
        ) {
          setOrderValue(temporaryOrderValue || 2);
        }
        dispatch(resetStatus());
        popUpSuccessr(orderSubmitted?.message, "success");
        allowNav && props.nextActivity();
        dispatch(setActivityEnable({ id: 1, enable: false }));
        const completionStatus = submissionCompletionStatus({ orderValue });
        allowNav &&
          dispatch(getOrderManageObj({ status: completionStatus, id: 1 }));
      }
    }
  }, [orderSubmitStatus, orderSubmitted]);

  return (
    <Fragment>
      {orderSubmitStatus && !orderSubmitError && <Loader />}
      <Box ref={inputRef} display="flex" alignItems={"center"} gap={2}>
        Orders need to be submitted at least
        <SelectionAccounnts
          value={orderValue}
          valueChange={(e) => valueChange(e)}
          orderRangeList={orderRangeList}
        />
        in advance of the “invoicing” date
      </Box>
    </Fragment>
  );
});

export default OrderManagement;
