/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

export default function Counter({ getResetCounter }) {
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    let interval;
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    } else {
      getResetCounter(counter);
    }

    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  return (
    <div>
      <Typography variant="body1">Resend in {counter} seconds</Typography>
    </div>
  );
}
