import {  createSlice } from '@reduxjs/toolkit'

const initialState = {
  createContractsloading: false,
  contractCreatedResponse: {},
  contractCreatedError: false,


}

const userReducer = createSlice({
  name: 'contractReducer',
  initialState,
  reducers: {
    createContracts(state) {
      return { ...state, createContractsloading: true };
    },
    createContracts_successful(state, action) {
      return {
        ...state, contractCreatedResponse: action.payload,
        createContractsloading: false
      }
    },
    createContracts_error(state, action) {
      return {
        ...state, contractCreatedResponse: action.payload.body,
        contractCreatedError: true
      }
    },
    resetStatus(state) {
      return {
        ...state, createContractsloading: false,
        contractCreatedError: false,
        contractCreatedResponse: {}
      };
    },
  }
})

export const {
  createContracts,
  createContracts_successful,
  createContracts_error,
  resetStatus
} = userReducer.actions;

export default userReducer.reducer
