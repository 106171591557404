import { Fragment, useState } from "react";
// material-ui
import {
  Avatar,
  Grid,
  List,
  ListItemAvatar,
  ListItemText,
  ListItem,
} from "@mui/material";
import MainCard from "../../components/MainCard";
import Typography from "@mui/material/Typography";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import Tooltip from "@mui/material/Tooltip";
import GroupsIcon from "@mui/icons-material/Groups";
import SpaIcon from "@mui/icons-material/Spa";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import InfoIcon from "@mui/icons-material/Info";

const CardList = [
  {
    primaryCount: 0,
    icon: <SpaIcon sx={{ color: "#388e3c" }} />,
    bgIconColor: "",
    isArrowUp: true,
    avatarSX: { backgroundColor: "#dcedc8" },
    spanColor: { color: "#89D329" },
    title: "Trial Locations",
    key: "trial_location",
    tooltipTitle:
      "The number of trial locations that have been planned and pushed to Bayagri+ for distributors. This means that trial plans assigned for Bayer sales/MD teams are not included in this number.",
  },
  {
    title: "Total Users (external)",
    primaryCount: 0,
    icon: (
      <GroupsIcon sx={{ color: "#039be5" }} style={{ width: 35, height: 35 }} />
    ),
    bgIconColor: "",
    isArrowUp: true,
    avatarSX: { backgroundColor: "#BBDEFB" },
    spanColor: { color: "#89D329" },
    key: "total_users",
    tooltipTitle:
      "The number of total users is based on all the users that have currently been setup in Bayagri+. This number does not include internal Bayer people.",
  },
  {
    title: "Seed Requests",
    primaryCount: 0,
    icon: <ShoppingCartCheckoutOutlinedIcon sx={{ color: "#00617F" }} />,
    bgIconColor: "",
    isArrowUp: false,
    avatarSX: { backgroundColor: "#00617f1a" },
    spanColor: { color: "#D0021B" },
    key: "seed_request",
    tooltipTitle:
      "A seed request is a request that user has made to one of our partners. This can be FOC seeds or a real commercial order. The intent of seed requests is to purely have a demand flag, it's not considered as a formal order.",
  },
  {
    title: "Conversion Rate",
    primaryCount: 0,
    icon: <CurrencyExchangeIcon sx={{ color: "#2B6636" }} />,
    bgIconColor: "",
    isArrowUp: true,
    avatarSX: { backgroundColor: "#2b663642" },
    spanColor: { color: "#89D329" },
    key: "conversion_rate",
    tooltipTitle: "",
  },
];
const TargetCount = ({
  callbackLoader,
  selectedCountryCode,
  loggedUser,
  trialLocCount,
  totalUserCount,
  totalSeedCount,
}) => {
  // eslint-disable-next-line
  const [monthData, setMonthData] = useState(CardList);
  const percentageValue = (totalSeedCount / totalUserCount) * 100;
  const convsRate= percentageValue ? `${percentageValue?.toFixed(0)}%` :"0%";
  const displayTotal = (condIf) => {
    switch (condIf) {
      case "trial_location":
        return trialLocCount;
      case "total_users":
        return totalUserCount;
      case "seed_request":
        return totalSeedCount;
      case "conversion_rate":
        return convsRate;
      default:
        return 0;
        // break;
    }
  };
  return (
    monthData?.length > 0 &&
    monthData.map((cl, i) => {
      return (
        <Fragment key={i}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MainCard
              title={cl?.title}
              elevation={0}
              border={false}
              content={true}
              contentSX={{ p: 0 }}
              headerSX={{ p: 1.5, color: "#707070" }}
              secondary={
                <>
                  {cl?.title !== "Conversion Rate" && (
                    <Tooltip title={cl?.tooltipTitle} arrow>
                      <InfoIcon style={{ width: 25, height: 25 }} />
                    </Tooltip>
                  )}
                </>
              }
            >
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  display: "flex",
                  p: 0,
                  marginTop: "-10px",
                  alignItems: "center",
                }}
              >
                <ListItem sx={{ p: 1 }}>
                  <Fragment>
                    <ListItemAvatar>
                      <Avatar
                        style={{ width: 45, height: 45 }}
                        sx={cl.avatarSX}
                      >
                        {cl?.icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {displayTotal(cl?.key)}
                          </span>
                          <span style={{ fontSize: "16px" ,paddingLeft:"4px"}}>
                            {cl?.key!=="conversion_rate" &&
                             `(Target CY24: ${cl?.key==="seed_request"? "100":"500"})`}
                          </span>
                        </>
                      }
                      secondary={
                        <span>
                          {cl?.key==="conversion_rate" && (
                            <Typography variant="body2" component="span">
                              <span  style={{fontWeight: "bold" }}> {totalSeedCount}</span> Seeds requested <span style={{fontWeight: "bold" }}> {totalUserCount}</span> users
                            </Typography>
                          )}
                        </span>
                      }
                      primaryTypographyProps={{
                        color: cl?.key==="conversion_rate" ?"rgb(137, 211, 41)" :"#585858",
                      }}
                      secondaryTypographyProps={{
                        color: "#96A8AE",
                      }}
                    />
                  </Fragment>
                </ListItem>
              </List>
            </MainCard>
          </Grid>
        </Fragment>
      );
    })
  );
};

export default TargetCount;
