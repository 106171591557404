// material-ui
import { Box, useMediaQuery } from "@mui/material";
// import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ADMIN_ROLE } from "../../../constants";
import Authorization from "../../../utils/authorization";

// project import
// import Search from "./Search";
import Profile from "./Profile";
// import Notification from "./Notification";
import MobileSection from "./MobileSection";
import CountrySelect from "./CountrySelect";

import { useLocation } from "react-router-dom";
import YearSelect from "./YearSelect";
import { Fragment } from "react";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { pathname } = useLocation();
  const authUser = Authorization.getAuthUser();
  const userHasRequiredRole =
    authUser && [ADMIN_ROLE]?.includes(authUser.userrole) ? true : false;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* {!matchesXs && <Search />}
      <IconButton
        component={Link}
        // href=""
        // target="_blank"
        disableRipple
        color="secondary"
        title="refresh"
        sx={{ color: "text.primary", bgcolor: "grey.100" }}
      >
        <AutorenewIcon />
      </IconButton>

      <Notification /> */}
      {userHasRequiredRole && (
        <Fragment>
          <CountrySelect />
          {pathname === "/target-dashboard" && <YearSelect />}
        </Fragment>
      )}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </Box>
  );
};

export default HeaderContent;
