import { Fragment, useEffect, useState } from "react";
import { apiURL } from "../../services/apiUrlConfig";
import { getRequest } from "../../services/apiUtils";
import { openSnackbar } from "../../components/ui/Toastr";
// material-ui
import {
  Avatar,
  Grid,
  List,
  ListItemAvatar,
  ListItemText,
  ListItem,
} from "@mui/material";
import upArrow from "../../assets/images/up_arrow.svg";
import downArrow from "../../assets/images/down_arrow.svg";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import MainCard from "../../components/MainCard";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import Typography from "@mui/material/Typography";
import { ErrorMsg } from "../../utils/helper";

const upArrowColor = { color: "#89D329" };
const downArrowColor = { color: "#D0021B" };

const CardList = [
  {
    primaryCount: 0,
    icon: <WebOutlinedIcon sx={{ color: "black", opacity: 1 }} />,
    bgIconColor: "",
    isArrowUp: true,
    avatarSX: { backgroundColor: "#62496324" },
    spanColor: { color: "#89D329" },
    title: "Page Vists for this Month",
    key: "pageVisits",
  },
  {
    title: "Logins for this Month",
    primaryCount: 0,
    icon: <LoginOutlinedIcon sx={{ color: "#DE0043" }} />,
    bgIconColor: "",
    isArrowUp: true,
    avatarSX: { backgroundColor: "#de00433d" },
    spanColor: { color: "#89D329" },
    key: "LoginForTheMonth",
  },
  {
    title: "Posts created for this Month",
    primaryCount: 0,
    icon: <UploadFileOutlinedIcon sx={{ color: "#00617F" }} />,
    bgIconColor: "",
    isArrowUp: false,
    avatarSX: { backgroundColor: "#00617f1a" },
    spanColor: { color: "#D0021B" },
    key: "PostCreated",
  },
  {
    title: "Pictures posted for this Month",
    primaryCount: 0,
    icon: <InsertPhotoOutlinedIcon sx={{ color: "#2B6636" }} />,
    bgIconColor: "",
    isArrowUp: true,
    avatarSX: { backgroundColor: "#2b663642" },
    spanColor: { color: "#89D329" },
    key: "picturesPosted",
  },
];
const MonthCount = ({ callbackLoader, selectedCountryCode, loggedUser }) => {
  const [monthData, setMonthData] = useState(CardList);
  useEffect(() => {
    getMonthVisitedCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode]);

  const getMonthVisitedCount = () => {
    const { analytics } = apiURL;
    callbackLoader(true);
    getRequest({
      url:`${analytics.getMonth}/${loggedUser?.id}`,
      queryParams: {
        countryCode: selectedCountryCode === "ALL" ? null : selectedCountryCode
      },
    })
      .then((response) => {
        callbackLoader(false);
        const result = response?.body?.data;
        if (result?.length > 0) {
          let objectValue = result[0];
          let keyAndValueArray = [];
          for (let property in objectValue) {
            let obj = { key: property, value: objectValue[property] };
            keyAndValueArray.push({ ...obj });
          }
          const output = monthData.map((el) => {
            keyAndValueArray.map((val) => {
              if (el.key === val.key) {
                return (el = {
                  ...el,
                  primaryCount: Object.assign({}, ...val.value),
                });
              }
              return el;
            });
            return el;
          });
          setMonthData(output);
        }else{
          setMonthData(CardList);
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  return (
    monthData?.length > 0 &&
    monthData.map((cl, i) => {
      const condUpAndDown = !cl?.primaryCount?.diff
        ? true
        : cl?.primaryCount?.currentMonth > cl?.primaryCount?.lastMonth
        ? true
        : false;
      return (
        <Fragment key={i}>
          {/* {isLoader && <Loader />} */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MainCard
              title={cl?.title}
              elevation={0}
              border={false}
              content={true}
              contentSX={{ p: 0 }}
              headerSX={{ p: 1, color: "#707070" }}
            >
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  display: "flex",
                  p: 0,
                  marginTop: "-10px",
                  alignItems: "center",
                }}
              >
                <ListItem sx={{p:1}}>
                  <Fragment>
                    <ListItemAvatar style={{ marginRight: "8px" }}>
                      <Avatar
                        style={{ width: 55, height: 55 }}
                        sx={cl.avatarSX}
                      >
                        {cl?.icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                          {cl?.primaryCount?.currentMonth}
                        </span>
                      }
                      secondary={
                        <span>
                          <span
                            style={
                              condUpAndDown ? upArrowColor : downArrowColor
                            }
                          >
                            {cl?.primaryCount?.diff !== null ? (
                              <img
                                style={{
                                  height: "9px",
                                  width: "17px",
                                  marginRight: "0.35vw",
                                }}
                                src={condUpAndDown ? upArrow : downArrow}
                                alt="UPAndDown"
                              />
                            ) : null}
                            {cl?.primaryCount?.diff?.toFixed(2)}
                            {cl?.primaryCount?.diff ? "%" : ""}&nbsp;
                          </span>
                          <Typography variant="body2" component="span">
                            From last month
                          </Typography>
                        </span>
                      }
                      primaryTypographyProps={{
                        color: "#585858",
                      }}
                      secondaryTypographyProps={{
                        color: "#96A8AE",
                      }}
                    />
                  </Fragment>
                </ListItem>
              </List>
            </MainCard>
          </Grid>
        </Fragment>
      );
    })
  );
};

export default MonthCount;
