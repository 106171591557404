// eslint-disable-next-line
import { createSlice, nanoid } from '@reduxjs/toolkit';
const initialState = {
        loginDetails:[]
}
 const loginSlice = createSlice(
    {
        name: 'login',
        initialState,
        reducers: {
            loginDetails: (state, action) => {
                state.loginDetails.push(action.payload)
            }
        }

    }
)

export  const {loginDetails}  = loginSlice.actions;
export default loginSlice.reducer;