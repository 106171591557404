import { useEffect, useState} from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
// import { openSnackbar } from "../../components/ui/Toastr";
// import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
// import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import {
    Box,
   // ClickAwayListener, 
   // IconButton,
    Paper,
   // Popper,
   // Typography,
  } from "@mui/material";
 // import OutlinedInput from "@mui/material/OutlinedInput";
 // import TableFooter from "@mui/material/TableFooter";
 // import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
 // import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

  // const customTheme = (createTheme) => ({
  //   components: {
  //     MuiOutlinedInput: {
  //       styleOverrides: {
  //         root: {
  //           "& .MuiOutlinedInput-notchedOutline": {
  //             border: `0.5px solid lightgrey`,
  //           },
  //           "&.Mui-focused": {
  //             "& .MuiOutlinedInput-notchedOutline": {
  //               border: `0.5px solid lightgrey`,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // });

  
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00617F",
      color: "#FFFFFF",
      "&:last-child": {
        borderTopRightRadius: "4px",
      },
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "#FFFFFF",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: 5,
    },
    [`&.${tableCellClasses.footer}`]: {
      backgroundColor: "#F5F5F5",
      color: "#585858",
      lineHeight: "1.4rem",
    },
  }));

  function CustomTableHead(props) {
    const {
      onSelectAllClick,
      numSelected,
      rowCount,
      headCells,
    } = props;

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </StyledTableCell>
          {headCells?.length > 0 &&
            headCells.map((headCell) => (
              <StyledTableCell
                key={headCell.id}
                align={headCell.numeric ? "left" : "left"}
                style={{
                  minWidth: headCell.minWidth,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.3,
                  paddingLeft:headCell.disablePadding ? 20:0
                }}
              >
                {headCell.label}
              </StyledTableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  }
  
  CustomTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const CustomTable = (props) => {
      const {
        from,
        rows,
        headCells,
        getFilterData,
       // handleInput,
       // selectedChecked,
        statusLoad
      } = props

      const [selectedList, setSelectedList] = useState([])

      const handleClick = (event, row) => {
      const listData = [...rows]
      const changeSelection = listData.map((e,index)=>{
          if(e?.sfid === row?.sfid){
            const elmnt = {...e}
            elmnt.isselected = event.target.checked 
            return elmnt
          }else{
              return e
          }
      })
      getFilterData({
        selectedRow: changeSelection,
      });
      
      }

      const handleSelectAllClick = async (event) => {
        const listData = [...rows]
        const tempSel = listData?.length > 0 && listData.map((itm) => {
            const elmnt = {...itm}
            elmnt.isselected = event.target.checked?true:false
            return elmnt
        })
        getFilterData({
            selectedRow: tempSel,
          });
        console.log("jsjsjsjsj",tempSel)
      }

      useEffect(()=>{
      const selected = rows?.length > 0 && rows.filter((itm)=> {return itm.isselected})
      setSelectedList(selected)
      },[rows])

      const LoadWhile = () => {
        return statusLoad ? (
          [...Array(4)].map((item,itemIndex) => {
            return (
              <TableRow key={itemIndex}>
                <TableCell
                  colSpan={headCells?.length + 1}
                  sx={{ textAlign: "center" }}
                  style={{
                    borderBottom: "0px solid transparent",
                    paddingBottom: 0.5,
                    paddingRight: 2,
                    paddingLeft: 0,
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }}
                  />
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
              {" "}
              No records found!
            </TableCell>
          </TableRow>
        );
        }    
console.log("llllllll",rows)
      return (
        <Box>
          <TableContainer
            sx={{
              maxHeight: {
                xl: 1000,
                lg: from === 'FORECAST'?290:380,
              },
            }}
            component={Paper}
            elevation={0}
          >
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" stickyHeader>
                <CustomTableHead
                numSelected={selectedList?.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>
                {
                  rows?.length > 0 ? (
                    rows.map((row, index) => {
                      const labelId = `Custom-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={false}
                          tabIndex={-1}
                          key={row?.id}
                          selected={false}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell
                            style={{ width: "6%" }}
                            padding="checkbox"
                          >
                            <Checkbox
                              onClick={(event) => handleClick(event, row)}
                              color="primary"
                              checked={row.isselected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </StyledTableCell>
                          {headCells?.length > 0 &&
                            headCells.map((hc) => {
                              return (
                                <StyledTableCell
                                  align={hc.numeric ? "left" : "left"}
                                  style={{
                                    width: hc.minWidth,
                                    fontSize: 12,
                                    paddingRight: hc.isLast ? 30 : 0,
                                    paddingLeft:hc.disablePadding ? 20:0
                                  }}
                                  key={hc?.id}
                                >{row[hc?.id]}</StyledTableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <LoadWhile />
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    }
    
    export default CustomTable

  
  