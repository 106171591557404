import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { apiURL } from "../../services/apiUrlConfig";
import { getRequest } from "../../services/apiUtils";
import { openSnackbar } from "../../components/ui/Toastr";
import { ErrorMsg } from "../../utils/helper";

const activeUsersOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grace: "50%",
    },
  },
};

const activeUsersData = {
  labels: [""],
  datasets: [
    {
      label: "Total Users",
      data: [],
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      condKey: "totalusers",
    },
    {
      label: "Active Users",
      data: [],
      backgroundColor: "#abed55",
      condKey: "activeusers",
    },
  ],
};

const ActiveUsers = ({ callbackLoader, selectedCountryCode, loggedUser }) => {
  const [currentMonthByUserData, setCurrentMonthByUserData] =
    useState(activeUsersData);

  useEffect(() => {
    getLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode]);

  const getLoadData = () => {
    const { analytics } = apiURL;
    callbackLoader(true);
    getRequest({
      url:`${analytics.getCurrentByMonthUser}/${loggedUser?.id}`,
      queryParams: {
        countryCode: selectedCountryCode === "ALL" ? null : selectedCountryCode
      },
    })
      .then((response) => {
        callbackLoader(false);
        const result = response?.body?.data;
        if (result?.length > 0) {
          let objectValue = result[0];
          let keyAndValueArray = [];
          for (let property in objectValue) {
            let obj = { key: property, value: objectValue[property] };
            keyAndValueArray.push({ ...obj });
          }
          const dataSetData = currentMonthByUserData?.datasets?.map((el) => {
            keyAndValueArray.map((val) => {
              if (el.condKey === val.key) {
                return (el = { ...el, data: [Number(val.value)] });
              }
              return el;
            });
            return el;
          });
          setCurrentMonthByUserData({
            ...currentMonthByUserData,
            datasets: dataSetData,
          });
        }else{
          setCurrentMonthByUserData(activeUsersData);
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  return (
    <Bar
      options={activeUsersOptions}
      data={currentMonthByUserData}
      height={"350px"}
    />
  );
};

export default ActiveUsers;
