import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export function CustomButton(props) {
  const { label, handleClick, disabled, variant, sx, type, labelSX ,fullWidth,className,labelClassName} = props;
  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      sx={sx}
      type={type}
      className={className}
    >
      <Typography variant="button" sx={labelSX} className={labelClassName}>
        {label}
      </Typography>
    </Button>
  );
}
