// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.85rem',
          padding: 12,
          borderColor: theme.palette.divider
        },
        head: {
          fontWeight: 600,
          paddingTop: 20,
          paddingBottom: 20
        },
        footer: {
          left: 0,
          bottom: 0, 
          zIndex: 2,
          position: 'sticky'
      }
      }
    }
  };
}
