import {  createSlice } from '@reduxjs/toolkit'



const initialState = {
  orderManagementDetails: {},
  orderdetailsloading: false,
  orderdetailsError: false,
  orderPath: false,
  ORDER: '',
  PAYMENT: ''
}
const userReducer = createSlice({

  name: 'orderMngmntReducer',
  initialState,
  reducers: {
    setPaymentActivityData(state, action) {
      state.PAYMENT = action.payload
    },
    setActivityData(state, action) {
      state.ORDER = action.payload
    },
    getOrderDetails(state, action) {
      state.orderManagementDetails = action.payload
    },
    updateOrderdetails(state) {
      return { ...state, orderdetailsloading: true };
    },
    getOrderdetails_successful(state, action) {
      return {
        ...state, orderManagementDetails: action.payload,
        orderdetailsloading: false
      }
    },
    getOrderdetails_error(state, action) {
      return {
        ...state, orderManagementDetails: action.payload.body,
        orderdetailsError: true
      }
    },
    resetStatus(state) {
      state.orderManagementDetails = {}
      // return {
      //   ...state, orderdetailsloading: false,
      //   orderdetailsError: false,
      //   orderManagementDetails: {}
      // };
    },
  }
})
export const {
  setPaymentActivityData,
  setActivityData,
  getOrderDetails,
  updateOrderdetails,
  getOrderdetails_successful,
  getOrderdetails_error,
  resetStatus,
  setOrderDetails,
} = userReducer.actions;

export default userReducer.reducer