import logo from "../../assets/images/bayagri_logo.png";
import PPPLogo from "../../assets/images/ppp_logo.png";
import Authorization from "../../utils/authorization";

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ width = 55, height = 55, style }) => {
  return (
    <img
      src={Authorization.isCMMAdmin()? PPPLogo : logo}
      alt="Logo"
      width={width}
      height={height}
      style={style}
    />
  );
};

export default Logo;
