import Box from "@mui/material/Box";
import { default as Select, components } from "react-select";
import Checkbox from "@mui/material/Checkbox";

const flexStyle = { display: "flex", alignItems: "center", gap: 2 };
const customStyles = {
  control: (provided, { selectProps: { width, value } }) => ({
    ...provided,
    width: width,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isMulti
        ? state.isSelected
          ? "#262626"
          : "inherit"
        : state.isSelected
        ? "#fff"
        : "inherit",
      backgroundColor: state.isMulti
        ? state.isSelected
          ? "transparent"
          : "inherit"
        : state.isSelected
        ? "#2684FF"
        : "inherit",
    };
  },
  menu: (base) => ({ ...base, zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
const additionalOption = (props) => {
  return (
    <components.Option {...props}>
      <Checkbox
        checked={props.isSelected}
        onChange={() => null}
        sx={{ p: "5px" }}
      />
      {/* <input type="checkbox" checked={props.isSelected} onChange={() => null} /> */}
      <label>{props.label}</label>
    </components.Option>
  );
};
const ReactSelect = (props) => {
  const {
    label,
    options,
    handleChange,
    handleInputChange,
    value,
    name,
    isDisabled,
    width,
    placeHolder,
    getOptionLabel,
    className,
    maxMenuHeight,
    labelPosition,
    isMulti,
    isAddCompt,
    isCloseMenuOnSelect,
    isHideSelectedOptions,
  } = props;
  let labelStyle = labelPosition ? flexStyle : {};
  const defaultOptions = [{ value: "ALL", label: "ALL" }];
  const condComponents = isAddCompt ? { Option: additionalOption } : {};
  return (
    <Box sx={labelStyle}>
      <label>{label}</label>
      <Select
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        isMulti={isMulti}
        value={
          options?.length > 0 && !isMulti
            ? options.filter(function (option) {
                return option.value === value;
              })
            : value
        }
        placeholder={placeHolder ? placeHolder : label}
        isClearable={false}
        isSearchable
        name={name}
        defaultValue={defaultOptions && defaultOptions[0]}
        isDisabled={isDisabled}
        noOptionsMessage={() => "No records found!"}
        components={condComponents}
        styles={customStyles}
        width={width}
        getOptionLabel={getOptionLabel}
        className={className}
        maxMenuHeight={maxMenuHeight && maxMenuHeight}
        closeMenuOnSelect={isCloseMenuOnSelect}
        hideSelectedOptions={isHideSelectedOptions}
        menuPortalTarget={document.body} 
      />
    </Box>
  );
};
export default ReactSelect;
