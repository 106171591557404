/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import CustomTable from "../../../../components/ui/Table";
import { Box, Paper,Typography } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from "react-redux";
import {setForecastSelection,setVarietiesForecast,getMarketIntelligenceSelected, getActivitiesSelected, setActivitySum } from "../../../../store/reducers/contracts";
import { getActivities } from "../../../../saga/saga/activitiesSaga"
import Authorization from "../../../../utils/authorization"
import util from 'util'
import { apiURL } from "../../../../services/apiUrlConfig";
import { openSnackbar } from "../../../../components/ui/Toastr";
import Loader from "../../../../components/ui/Loader";
import { resetStatus } from "../../../../saga/saga/contractSaga"
import { setSalesVolumeActivityData } from "../../../../saga/saga/salesVolumeSaga"
import { setPaymentActivityData,setActivityData } from "../../../../saga/saga/orderMangeSaga"
import { setmarketIntelligenceActivityData } from "../../../../saga/saga/MarketIntelligenceSaga"

import { setforeCastActivityData } from "../../../../saga/saga/foreCastSaga"
import { resetActivities } from "../../../../store/reducers/activities";


const SUCCESS = 200

const activityImages = {
  1: "../../../../assets/images/1.svg",
  2: "../../../../assets/images/1.svg",
  3: "../../../../assets/images/1.svg",
  4: "../../../../assets/images/1.svg",
  5: "../../../../assets/images/1.svg",
  6: "../../../../assets/images/1.svg",
  7: "../../../../assets/images/1.svg",
  8: "../../../../assets/images/1.svg",
  9: "../../../../assets/images/1.svg",
}

const CustomMenu = styled(MenuItem)({
  height:30,
  fontSize:11,
  letterSpacing:0.5
});
const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-input':{
   display:'flex'
 }
 }));



function createData(id, name, calories, weighted, fat, carbs, protein, activityID, icon, disable) {
  return {
    id,
    name,
    calories,
    weighted,
    fat,
    carbs,
    protein,
    activityID,
    icon,
    disable

  };
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Activity",
    minWidth: 200,
    isInputBox: false,
    isLast: false,
  },
  {
    id: "calories",
    numeric: false,
    disablePadding: false,
    label: "Conditional discount (%)",
    minWidth: 120,
    isInputBox: true,
    isLast: false,

  },
  {
    id: "weighted",
    numeric: true,
    disablePadding: true,
    label: "Weighted discount (%)",
    minWidth: 95,
    isInputBox: false,
    isLast: false,

  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "PY conditional discount (%)",
    minWidth: 120,
    isInputBox: false,
    isLast: false,

  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Simulated discount value* (€)",
    minWidth: 150,
    isInputBox: false,
    isLast: false,

  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Discount value last year (€)",
    minWidth: 150,
    isInputBox: false,
    isLast: true,

  },
];

const preSelectForEditAct = (totalaccounts) => {
  let ActivityData = []
  ActivityData = totalaccounts?.length > 0 ? totalaccounts.map((itm) => {

    return createData(itm.activitytypeid || 0, itm.activityname || '', itm.conditionaldiscountpercent||0.00, itm.weighteddiscountpercent||0.00, itm.pyconditionaldiscountpercent || 0.00, itm.simulateddiscount||0.00, itm.pydiscountvalue || 0.00, (itm.activityname).replaceAll(' ', '_'), <img src={activityImages[itm.id]} alt={itm.activityname} />, true)
  }):[]
  return ActivityData
}

const setActivityOrder = (
  data, 
  totalaccounts,isFor,
  cDicPer = 'conditionaldiscountpercent', 
  pyCDicPer = 'pyconditionaldiscountpercent',
  simDicVal = 'simulateddiscount',
  pyDicVal = 'pydiscountvalue',
  weightedper = 'weighteddiscountpercent'

  ) => {
  const selectedAccount = totalaccounts && totalaccounts.filter(x => typeof x !== "undefined").shift();
  const firtAccount = isFor === 'EDIT'?totalaccounts:selectedAccount?.activitytargets ?? []
  let orderedAccountbycondion = {}
  firtAccount.forEach((itm) => {
    orderedAccountbycondion[`${itm.activitytypeid + cDicPer}`] = itm[cDicPer]
    orderedAccountbycondion[`${itm.activitytypeid + pyCDicPer}`] = itm[pyCDicPer]
    orderedAccountbycondion[`${itm.activitytypeid + simDicVal}`] = itm[simDicVal]
    orderedAccountbycondion[`${itm.activitytypeid + pyDicVal}`] = itm[pyDicVal]
    orderedAccountbycondion[`${itm.activitytypeid + weightedper}`] = itm[weightedper]

  });
  let ActivityData = []
  ActivityData = data?.length > 0 ? data.map((itm) => {
    let pyconditionaldiscountpercent = orderedAccountbycondion[`${itm.id + pyCDicPer}`];
    let pydiscountvalue = orderedAccountbycondion[`${itm.id + pyDicVal}`];
    let conditionalDiscountValue = orderedAccountbycondion[`${itm.id + cDicPer}`];
    let simulationValue = orderedAccountbycondion[`${itm.id + simDicVal}`];
    let weightedPercentage = orderedAccountbycondion[`${itm.id + weightedper}`];



    return createData(itm.id || 0, itm.name || '', conditionalDiscountValue||0.00, weightedPercentage || 0.00, pyconditionaldiscountpercent || 0.00, simulationValue||0.00, pydiscountvalue || 0.00, (itm.name).replaceAll(' ', '_'), <img src={activityImages[itm.id]} alt={itm.name} />, true)
  }) : []

  return ActivityData
}

const Activities = (props) => {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const [Cshallowactivities, setCshallowActivities] = useState([]);
  const [Calpyt, setCalpyt] = useState([])
  const [currency, setCurrency] = useState('')
  const [countryHeaderCells ,setCountryHeaderCells] = useState([...headCells])
  const [activitiesSelected, setActivitiesSelected] = useState([]);
  const [accounts, setaccounts] = useState([]);
  const activitiesList = useSelector((state) => state.activitiesSaga.activitiesList);
  const activitiesStatus = useSelector((state) => state.activitiesSaga.activitiesloading);
  const accountsSelected = useSelector((state) => state.contracts.accounts);
  const activitiesSelectedList = useSelector((state) => state.contracts.activitiesList);
  const createContractStatus = useSelector((state) => state.contractSaga.createContractsloading);
  const activitiesCreated = useSelector((state) => state.contractSaga.contractCreatedResponse);
  const contractCreatedError = useSelector((state) => state.contractSaga.contractCreatedError);
  const getConditionalValues = useSelector((state) => state.contracts.condsAndUncondsObj);
  const isAmericanCountry = Authorization.isAmerica()
  const TOTALCALVALUE = getConditionalValues?.cDiscount
  const cDisValue = getConditionalValues?.cDiscount
 // const [totalCalValue] = useState(TOTALCALVALUE);
  const ContractActionType = useSelector((state) => state.common.ContractActionType);



  const getApi = apiURL.ppp.getActivities ?? ''
  const userDetaisl = Authorization.authUser
  const activitisApi = util.format(getApi, userDetaisl.id)
  useEffect(() => {
    if(isAmericanCountry){
      setCurrency('$')
      const newCountryCells = headCells&&headCells.map((itm)=>{
        const crncrt = itm.label.replace('€','$')
        const aftCrncrt = {...itm,label:crncrt}
        return aftCrncrt
      })
      newCountryCells[1] = headCells[2]
      newCountryCells[2] = headCells[1]
      newCountryCells[1].isInputBox = true
      newCountryCells[2].isInputBox = false
      setCountryHeaderCells([...newCountryCells])
      setCalpyt('TAG')
      }else{
        setCurrency('€')
        const newCountryCells = headCells?.length > 0 && headCells.filter((itm)=>{
          // eslint-disable-next-line eqeqeq
          return itm?.id != 'weighted'
        })
        newCountryCells[1].isInputBox = true
        setCountryHeaderCells([...newCountryCells])  
        setCalpyt('PYS')

      }

    dispatch(getActivities(activitisApi))
    dispatch(setSalesVolumeActivityData({}))
    dispatch(setPaymentActivityData())
    dispatch(setActivityData())
    dispatch(setforeCastActivityData([]))
    dispatch(setForecastSelection([]))
    dispatch(setVarietiesForecast([]))
    dispatch(getMarketIntelligenceSelected([]))
    dispatch(resetActivities())
    dispatch(setmarketIntelligenceActivityData([]))
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const extractActivityInfo = async () => {
    let isFor = 'CREATE'
    if(ContractActionType&&ContractActionType?.type === 'EDIT'){
      isFor = 'EDIT'
    }
    const updatedActivityList = await setActivityOrder(activitiesList, accountsSelected,isFor)
    setActivities(updatedActivityList)
  }

  const extractEditActivityInfo = async () => {
    let isFor = 'CREATE'
    if(ContractActionType&&ContractActionType?.type === 'EDIT'){
      isFor = 'EDIT'
    }
    if(isFor === 'EDIT'&&accountsSelected){
      // preSelectForEditAct
      const updatedPreActivityList = await preSelectForEditAct(accountsSelected,isFor)
      dispatch(getActivitiesSelected({ activitiesList: updatedPreActivityList }));
    }
  }

  useEffect(()=>{
    const handleTabClose = event => {
      event.preventDefault();
      return(event.returnValue = 'Are you want to exit?');
    }
    window.addEventListener('beforeunload',handleTabClose)
     return()=>{
       window.removeEventListener('beforeunload',handleTabClose)
     }
    
  },[])

  useEffect(() => {
    setaccounts(accounts)
    // extractActivityInfo()
    if (accountsSelected?.length > 0) extractEditActivityInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsSelected])

  useEffect(() => {
    setActivities(activitiesList)
    if (activitiesList?.length > 0) extractActivityInfo()
  }, [activitiesList])

  useEffect(() => {
    setActivitiesSelected(activitiesSelectedList)
  }, [activitiesSelectedList])

  const handleInputChange = async(e, row, selectionall, hc = 'calories', wdv = 'weighted', simLtd = 'carbs') => {
    setCshallowActivities([])
    if(isAmericanCountry){
      setCalpyt('TAG')
    }else{
      setCalpyt('PYS')
    }
    if (selectionall) {
      extractActivityInfo()
    } else {
      const value = (typeof e === 'object') ? (e.target.value).replace(/^0+/, '') : e
      let weightedValue 
      let ConditionalValue
      let sumConsiderField = hc
      let calSimValueCountry
      if(isAmericanCountry){
        weightedValue = value
        ConditionalValue = (((value)/ 100)*cDisValue).toFixed(2)
        sumConsiderField = hc
        calSimValueCountry = (getConditionalValues?.targetSum)*(ConditionalValue/100)
      }else{
        weightedValue = ((value)/ TOTALCALVALUE).toFixed(2)
        ConditionalValue = value
        sumConsiderField = hc
        calSimValueCountry = (getConditionalValues?.totalPYsales)*(ConditionalValue/100)
      }
      const weightedDiscount = weightedValue
      const conditionalVal = ConditionalValue
      const calSimValue = calSimValueCountry
      // (getConditionalValues?.totalPYsales)*(ConditionalValue/100)
      const updatedRow = { ...row, [wdv]: weightedDiscount, [hc]:(conditionalVal || 0), [simLtd]: calSimValue.toFixed(2) }
      const listedActivities = [...activities]
      const updatedlistedActivities_sel = activitiesSelected && activitiesSelected.map(obj => [updatedRow].find(o => o.id === obj.id) || obj);
      const calSum_sel = updatedlistedActivities_sel.reduce((a, b) => (a + parseFloat(b[sumConsiderField])), 0);
      dispatch(setActivitySum({ activitySum: calSum_sel }))
      if (calSum_sel > TOTALCALVALUE) {
        setActivities(listedActivities)
        openSnackbar({
          message: `Sum of conditional discount is exceeding the limit!`,
          variant: "warning",
        });
      } else {
        // eslint-disable-next-line eqeqeq
        if (calSum_sel == TOTALCALVALUE) {
          const olyValue = updatedlistedActivities_sel.filter((item) => parseFloat(item[hc]) > 0)
          dispatch(getActivitiesSelected({ activitiesList: olyValue }));
          setActivitiesSelected(olyValue)
          const updatedlistedActivities = listedActivities.map(obj => olyValue.find(o => o.id === obj.id) || obj);
          setActivities(updatedlistedActivities)
        } else {
          dispatch(getActivitiesSelected({ activitiesList: updatedlistedActivities_sel }));
          setActivitiesSelected(updatedlistedActivities_sel)
          const updatedlistedActivities = listedActivities.map(obj => updatedlistedActivities_sel.find(o => o.id === obj.id) || obj);
          setActivities(updatedlistedActivities)
        }
      }
    
  }
  };

  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  }

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (createContractStatus == false && !activitiesCreated) {
      const errormsg = activitiesCreated?.message
      const noNetworkMessage = 'Please check internet or try again!'
      const msg = (activitiesCreated?.status) ? errormsg : noNetworkMessage
      popUpSuccessr(msg, 'error')
      dispatch(resetStatus())
    // eslint-disable-next-line eqeqeq
    } else if (activitiesCreated?.status == SUCCESS) {
      let isFor = 'CREATE'
    if(ContractActionType&&ContractActionType?.type === 'EDIT'){
      isFor = 'EDIT'
     }
      popUpSuccessr(`Contract has been ${isFor === 'EDIT'?'updated':'created'} successfully`, 'success')
      dispatch(resetStatus())
      props.renderNext()
    };
  }, [createContractStatus, activitiesCreated]);


  const getFilterData = (filterDatas) => {
    dispatch(getActivitiesSelected({ activitiesList: filterDatas?.selectedRow }));
  };

  const extionOfShflePYT = (selVal) => {
    const vlue = selVal
    setCalpyt(vlue)
    let devide 
    let multiply
    if(isAmericanCountry)
    {
      devide = (vlue === 'TAG'? (getConditionalValues?.totalPYsales) : (getConditionalValues?.targetSum))
      multiply = (vlue === 'PYS'?(getConditionalValues?.totalPYsales) : (getConditionalValues?.targetSum))
    }else{
      multiply = (vlue === 'TAG'? (getConditionalValues?.targetSum) : (getConditionalValues?.totalPYsales))
      devide = (vlue === 'PYS'?(getConditionalValues?.totalPYsales) : (getConditionalValues?.targetSum))
   }
    console.log("activitiesactivities",devide,multiply,activities)//carbs
    const srcList = (Cshallowactivities?.length)?Cshallowactivities:activities
    const relignActivities = srcList&&srcList.map((itm)=>{
      const activty = {...itm,carbs:((((itm.carbs)/devide)*multiply).toFixed(1))}
      return activty
    })
    return relignActivities
  }
  const CalpytChange = async(e)=>{
    const vlue = e.target.value
    const getextionOfShflePYT = await extionOfShflePYT(vlue)
    setCshallowActivities(getextionOfShflePYT)
  }

  const ReCheckValues =(props)=>{
    return(
      <Paper elevation={0} style={{display:'flex',flexDirection:'row',paddingRight:25,paddingBottom:8}}>
     <Typography style={{fontSize:12,letterSpacing:0.5,fontWeight:600}}>{props.title}</Typography>
     <Typography style={{fontSize:12}}>{props.value}</Typography>
     </Paper>
    )
  }
  console.log("activitiessolc",activities)
  return (
    <Fragment>
      {(createContractStatus && !contractCreatedError) && <Loader />}
      <Box>
        <Paper elevation={0} style={{display:'flex',flexDirection:'row'}}>
        <ReCheckValues title={`PY sales (${currency}) : `} value={getConditionalValues?.totalPYsales || 0}/>
        {isAmericanCountry&&<ReCheckValues title='Targets : ' value={getConditionalValues?.targetSum || 0}/>}
        <ReCheckValues title='Conditional discount (%) : ' value={getConditionalValues?.cDiscount || 0}/>
        {isAmericanCountry&&<Box style={{display:'flex'}}>
          <Typography style={{fontSize:12,letterSpacing:0.5,fontWeight:600}}>Simulation value associated with </Typography>
          <FormControl variant="standard" size="small">
          <StyledSelect
      // id={'TAGPYS'}
      labelId="demo-simple-select-standard-label"
      value={Calpyt}
      onChange={CalpytChange}
      // inputProps={{ 'aria-label': 'Without label' }}
      id="demo-simple-select-standard"
      style={{width:170,height:17,marginLeft:10,fontSize:12,display:'flex',letterSpacing:0.5}}
      sx={{display:'flex'}}
    >
      <CustomMenu value={'TAG'}>{'Target value'}</CustomMenu>
      <CustomMenu value={'PYS'}>{'PY Sales'}</CustomMenu>

    </StyledSelect>
    </FormControl>
    {/* <Typography style={{fontSize:9,width:260,paddingLeft:10,letterSpacing:0.5,fontWeight:600}}>*Actual values remains the same. </Typography> */}
        </Box>}
        </Paper>
        <CustomTable
          key = 'ACT'
          from='ACT'
          rows={(Cshallowactivities?.length)?Cshallowactivities:activities}
          statusLoad={activitiesStatus}
          headCells={countryHeaderCells}
          isPagination={false}
          selectedChecked={activitiesSelected}
          handleInput={(e, row, selection) => handleInputChange(e, row, selection)}
          isTableFooter={true}
          getFilterData={(filterDatas) => getFilterData(filterDatas)}
        ></CustomTable>
      </Box>
    </Fragment>
  );
};

export default Activities;
