import LeaderBoardTable from "./LeaderBoardTable";
const columns = [
  {
    width: 10,
    label: "",
    dataKey: "serial",
  },
  {
    width: 120,
    label: "Country",
    dataKey: "country",
  },
  {
    width: 100,
    label: "Active Users",
    dataKey: "activeUsers",
    numeric: true,
  },
  {
    width: 100,
    label: "Total Users",
    dataKey: "totalUsers",
    numeric: true,
  },
];
const UserLeader = ({ data }) => {
  return (
    <LeaderBoardTable
      title="User Leaderboard (external)"
      rows={data}
      headers={columns}
    />
  );
};

export default UserLeader;
