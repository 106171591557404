import React, { useEffect } from 'react'
import MiModal from './MiModal'
import { useActionData, useBlocker } from "react-router-dom";
import { DEFAULT_NAVI_MESSAGE, LOGOUT_NAVI_MESSAGE} from "../../constants"

const ConfirmationNavigation = (props) => {


    let actionData = useActionData()
    let blocker = useBlocker(({ currentLocation, nextLocation }) => props.checkData &&
        currentLocation.pathname !== nextLocation.pathname
    )


    useEffect(() => {
        if (actionData?.ok) {
            props.dataEmptyHandler()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionData]);



    useEffect(() => {
        if (blocker.state === "blocked" && !props.checkData) {
            blocker.reset();
        }
    })

    const okBlocker = () => {
        // props.dataEmptyHandler()
        blocker.reset()
    }
    const cancelBlocker = () => {
        props.dataEmptyHandler()
        blocker.proceed()
    }

    return (
        <>
            <MiModal open={(blocker.state === "blocked")}
                title="Confirmation"
                description={blocker?.location?.pathname === '/login' ? LOGOUT_NAVI_MESSAGE : DEFAULT_NAVI_MESSAGE }
                ok={{ buttonName: 'OK', ok: cancelBlocker }}
                cancel={{ buttonName: 'Cancel', cancel: okBlocker }} />

        </>
    )
}

export default ConfirmationNavigation