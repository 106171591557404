import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    foreCastDetails: {},
    foreCastloading: false,
    foreCastError: false,
    foreCast:{}
}
const userReducer = createSlice({

    name: 'foreCastReducer',
    initialState,
    reducers: {
        setforeCastActivityData(state, action) {
            console.log("jjjdjdjd::",action.payload)
            state.foreCast = {...action.payload}

        },
        getforeCastInfo(state) {
            return { ...state, foreCastloading: true };
        },
        updateforeCastInfo(state) {
            return { ...state, foreCastloading: true };
        },
        getforeCastInfo_successful(state, action) {
            return {
                ...state, foreCastDetails: action.payload,
                foreCastloading: false
            }
        },
        getforeCastInfo_error(state, action) {
            return {
                ...state, foreCastDetails: action.payload.body,
                foreCastError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, foreCastloading: false,
                foreCastError: false,
                foreCastDetails: [],
                foreCast:{}
            };
        },
    }
})
export const {
    getforeCastInfo,
    getforeCastInfo_successful,
    getforeCastInfo_error,
    resetStatus,
    updateforeCastInfo,
    setforeCastActivityData
} = userReducer.actions;

export default userReducer.reducer