/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { useEffect, useState, memo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import { openSnackbar } from "../../components/ui/Toastr";
import Authorization from "../../utils/authorization"
// import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
// import Tooltip from "@mui/material/Tooltip";
// import FilterListIcon from "@mui/icons-material/FilterList"; 
// import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import MainCard from "../MainCard";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
// import Sticky from 'react-sticky-el';

// import Transitions from "../@extended/Transitions";
import {
  Box,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
// import { CustomButton } from "../../components/ui/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import TableFooter from "@mui/material/TableFooter";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const customTheme = (createTheme) => ({
  components: {
    // Inputs
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `0.5px solid lightgrey`,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `0.5px solid lightgrey`,
            },
          },
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00617F",
    color: "#FFFFFF",
    "&:last-child": {
      borderTopRightRadius: "4px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#FFFFFF",
  },
  [`&.${tableCellClasses.root}`]: {
    padding: 5,
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: "#F5F5F5",
    color: "#585858",
    lineHeight: "1.4rem",
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function CustomTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell>
        {headCells?.length > 0 &&
          headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              // padding={headCell.disablePadding ? "none" : "normal"}
              // sortDirection={orderBy === headCell.id ? order : false}
              style={{
                minWidth: headCell.minWidth,
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.3,
                paddingLeft:headCell.disablePadding ? 20:0
              }}
            >
              {headCell.label}
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel> */}
            </StyledTableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export function CustomTableToolbar(props) {
  const { numSelected, children, sx ,clearFilter} = props;
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [isArrowUp, setIsArrowUp] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }
  //   setOpen(false);
  // };
  const condStyles = {
    ...sx,
    minHeight: 30,
  };
  return (
    <Toolbar sx={condStyles}>
      <Box sx={{width:"100%"}}>
        <Box
          display={"flex"}
          onClick={() => setIsArrowUp(!isArrowUp)}
          gap={1}
          justifyContent="space-between"
        >
          <Box display={"flex"} gap={1}>
            <Typography variant="h6" id="tableTitle" component="div">
              Filters
            </Typography>

            {!isArrowUp ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Box>
          {isArrowUp && (
            <Typography
              variant="h6"
              id="tableTitle1"
              component="div"
              color="#1976d2"
              sx={{ cursor: "pointer" }}
              onClick={clearFilter}
            >
              Clear Filters
            </Typography>
          )}
        </Box>
        {isArrowUp ? children : null}
      </Box>

      {/* <Tooltip title="Filter list">
        <IconButton
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popper
        placement={"left-start"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
        sx={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Filters"
                  elevation={0}
                  border={false}
                  content={true}
                  avatar={
                    <IconButton size="small" sx={{ marginRight: "-15px" }}>
                      <FilterListIcon />
                    </IconButton>
                  }
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseIcon />
                    </IconButton>
                  }
                  headerSX={{ p: 1.75 }}
                  contentSX={{
                    p: 1.75,
                    maxHeight: {
                      xl: 700,
                      lg: 380,
                      overflowY: "auto",
                    },
                  }}
                  isCardAction={true}
                  cardActionChildren={
                    <Box
                      display="flex"
                      justifyContent={"space-around"}
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <CustomButton
                        label="Reset"
                        sx={{
                          color: "#10384F",
                          borderRadius: "28px",
                          backgroundColor: "#FFFFFF",
                          border: "1.35px solid #10384F",
                          opacity: "1",
                          width: "110px",
                        }}
                        labelSX={{
                          fontWeight: 600,
                        }}
                      ></CustomButton>
                      <CustomButton
                        label="Apply"
                        sx={{
                          color: "#2B6636",
                          borderRadius: "28px",
                          backgroundColor: "#dcedc8",
                          opacity: "1",
                          "&:hover": {
                            backgroundColor: "#dcedc8",
                            color: "#66B512",
                            border: "1.35px solid #66B512",
                          },
                          width: "110px",
                        }}
                        labelSX={{
                          fontWeight: 600,
                        }}
                      ></CustomButton>
                    </Box>
                  }
                >
                  {children}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper> */}
    </Toolbar>
  );
}

CustomTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CustomTable({
  from,
  rows,
  headCells,
  getFilterData,
  isPagination,
  handleInput,
  isTableFooter,
  selectedChecked,
  getAPICall,
  totalCount,
  statusLoad,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const activitiesSelectedList = useSelector(
    (state) => state.contracts.activitiesList
  );
  const allActivitiesList = useSelector((state) => state.activitiesSaga.activitiesList);
  const getConditionalValues = useSelector((state) => state.contracts.condsAndUncondsObj);

  const [selected, setSelected] = useState(
    selectedChecked?.length ? selectedChecked : []
  );
  const [page, setPage] = useState(0);
  
  const [listOfFtValues, setlistOfFtValues] = useState(Array(5).fill(0))
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isAmericanCountry = Authorization.isAmerica()
  const showWeighted = isAmericanCountry?32:14;
  const outerTheme = useTheme();
  useEffect(() => {
    if (from === "ACT") setSelected(activitiesSelectedList);
  }, [activitiesSelectedList]);
  useEffect(() => {
    rows?.length>0&&footerCal(rows);
  }, [rows]);

  useEffect(() => {
    getFilterData &&
      getFilterData({
        selectedRow: selected,
      });
    footerCal(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selected]);

  const footerCal = (src) => {
    const totalConditionalValue = src.reduce((a, b) => a + parseFloat(b['calories']), 0).toFixed(2);
    const totalWeightedDiscountValue = src.reduce((a, b) => a + parseFloat(b['weighted']), 0).toFixed(2);
    const totalPyconditionalValue = src.reduce((a, b) => a + parseFloat(b['fat']), 0).toFixed(2);
    const totalSimconditionalValue = src.reduce((a, b) => a + parseFloat(b['carbs']), 0).toFixed(2);
    const totalLastyrconditionalValue = src.reduce(
      (a, b) => a + parseInt(b['protein']),
      0
    ).toFixed(2);
    const americaHeaderCells = [totalWeightedDiscountValue,totalConditionalValue,totalPyconditionalValue,totalSimconditionalValue,totalLastyrconditionalValue]
    const otherCountries = [totalConditionalValue,totalPyconditionalValue,totalSimconditionalValue,totalLastyrconditionalValue]
    setlistOfFtValues(isAmericanCountry?[...americaHeaderCells]:[...otherCountries])

  };

  const handleInputAction = (e, row, selection = false) => {
    // //console.log.log("jjjjjoooo",e.target)
    handleInput(e, row, selection);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const activityAllSelectHandler = async (event) => {
    let calSum_sel =  await selected.reduce((a, b) => parseFloat(a) + parseFloat(b["calories"]), 0);
    // if(isAmericanCountry){
    // calSum_sel = await selected.reduce((a, b) => parseFloat(a) + parseFloat(b["weighted"]), 0);
    // }else{
    // calSum_sel = await selected.reduce((a, b) => parseFloat(a) + parseFloat(b["calories"]), 0);

    // }
    const selectedCount = parseInt(selected?.length);
    const allCount = parseInt(allActivitiesList?.length);
    const TOTALCALVALUE = getConditionalValues?.cDiscount
    const diffRes = allCount - selectedCount;
    const allowedPer = (allCount+1) - calSum_sel;
    const revrestSum = calSum_sel > (allCount+1) ? calSum_sel - (allCount+1) : allowedPer
    const restSum =
      calSum_sel -
      (parseInt(allActivitiesList?.length) - parseInt(selected?.length));
    if (event) {
      console.log("issue sort",TOTALCALVALUE === calSum_sel,TOTALCALVALUE,calSum_sel)
      // if (calSum_sel >= (allCount+1) || allowedPer <= diffRes) {
        if (parseFloat(TOTALCALVALUE) === parseFloat(calSum_sel) ) {
          openSnackbar({
                message: `Max limit reached! please adjust selected activities value to proceed.`,
                variant: "warning",
              });
        // if (allowedPer <= diffRes) {
        //   openSnackbar({
        //     message: `Available limit is ${revrestSum}, selecteding ${diffRes} more activities !   .`,
        //     variant: "warning",
        //   });
        // }
        // else {
        //   openSnackbar({
        //     message: `Max limit reached! please adjust selected activities value to proceed.`,
        //     variant: "warning",
        //   })
        // }
      } else {
        setSelected(rows);
        getFilterData({
          selectedRow: rows,
        });
        return;
      }
    } else {
      handleInputAction(0, rows, true);
      setSelected([]);
    }
  };

  const handleSelectAllClick = async (event) => {
    if (from == "ACT") {
      activityAllSelectHandler(event.target.checked);
    } else {
      if (event.target.checked) {
        setSelected(rows);
        getFilterData({
          selectedRow: rows,
        });
        return;
      }

      setSelected([]);
    }
  };

  const activityHandleSelect = (event, row, hc = "calories") => {
    let identifyExistance = selected.filter((itm) => itm.id === row.id);
    const TOTALCALVALUE = getConditionalValues?.cDiscount
    const calSum_sel = selected.reduce((a, b) => a + parseFloat(b[hc]), 0);
    const selectedCount = parseInt(selected?.length);
    const allCount = parseInt(allActivitiesList?.length);
    const diffRes = allCount - selectedCount;
    const allowedPer = 10 - calSum_sel;
    const finalSel = selected.reduce((a, b) => {
      if (parseInt(b[hc]) < 1 && event) {
        return a + 1;
      } else {
        return a;
      }
    }, 0);
    // //console.log.log("lsjcjcjc::",calSum_sel,TOTALCALVALUE)
    if (calSum_sel === TOTALCALVALUE && event) {
      openSnackbar({
        message: `Unable to proceed! Max limit reached, In order to select please adjust selected activities value's.`,
        variant: "warning",
      });
    } else {
      // if (finalSel + calSum_sel >= 100 && event) {
      //   openSnackbar({
      //     message: `Unable to proceed! Since available limit is ${allowedPer}, but selecting ${selectedCount} more activities !.`,
      //     variant: "warning",
      //   });
      // } else {
        let eventEndArry = [...selected];
        if (identifyExistance?.length > 0) {
          eventEndArry = selected.filter((itm) => {
            return itm.id !== row.id;
          });
        } else {
          eventEndArry.push(row);
        }
        handleInputAction(0, row);
        setSelected(eventEndArry);
        getFilterData({
          selectedRow: eventEndArry,
        });
      // }
    }
  };

  const handleClick = (event, row) => {
    // //console.log.log("lllslsls9999",row)

    if (from == "ACT") {
      activityHandleSelect(event.target.checked, row);
    } else {
      let selectedIndex
      if(from === 'FORECAST'){
        selectedIndex = selected.map((e) => e?.sfid).indexOf(row?.sfid);
      }else{
        selectedIndex = selected.map((e) => e?.id).indexOf(row?.id);
      }
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      getFilterData({
        selectedRow: newSelected,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAPICall && getAPICall(newPage*rowsPerPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAPICall && getAPICall(0, event.target.value);
  };

  const LoadWhile = () => {
    return statusLoad ? (
      [...Array(4)].map((item,itemIndex) => {
        return (
          <TableRow key={itemIndex}>
            <TableCell
              colSpan={headCells?.length + 1}
              sx={{ textAlign: "center" }}
              style={{
                borderBottom: "0px solid transparent",
                paddingBottom: 0.5,
                paddingRight: 2,
                paddingLeft: 0,
              }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }}
              />
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow>
        <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
          {" "}
          No records found!
        </TableCell>
      </TableRow>
    );
    // {status?
    //   <TableRow><Skeleton animation="wave" variant="rectangular" sx={{ height:40,width:'100%'}} /></TableRow>
    // :<TableRow ><TableCell colSpan={headCells?.length+1} sx={{textAlign:"center",backgroundColor:'red'}}> No records found!</TableCell></TableRow>

    // }

    // return(
    //   [...Array(5)].map((item)=>{return <TableCell colSpan={headCells?.length+1} sx={{textAlign:"center"}}>
    //     <Skeleton animation="wave" variant="rectangular" sx={{ height:40,width:'100%'}} />
    //   </TableCell>})
    // )
  };

  const isSelected = (id) =>
    selected?.length > 0 && selected.map((e) => e?.id).indexOf(id) !== -1;
  
    const isSelectedForeCast = (sfid) =>
    selected?.length > 0 && selected.map((e) => e?.sfid).indexOf(sfid) !== -1;
  
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );
  const visibleRows = rows;
  // console.log("sjsjjsj333",headCells,visibleRows,selected)
  return (
    <Box>
      <TableContainer
        sx={{
          maxHeight: {
            xl: 1000,
            lg: from === 'FORECAST'?290:380,
          },
        }}
        component={Paper}
        elevation={0}
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" stickyHeader>
            <CustomTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {
              visibleRows?.length > 0 ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = (from === 'FORECAST')?isSelectedForeCast(row?.sfid):isSelected(row?.id);
                  // //console.log.log("selectedvvv1::",isItemSelected)
                  // //console.log.log("selectedvvv2::",row,row?.sfid)
                  // //console.log.log("selectedvvv3::",selected)

                  const labelId = `Custom-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <StyledTableCell
                        style={{ width: "6%" }}
                        padding="checkbox"
                      >
                        <Checkbox
                          onClick={(event) => handleClick(event, row)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledTableCell>
                      {headCells?.length > 0 &&
                        headCells.map((hc) => {
                          return (
                            <StyledTableCell
                              align={hc.numeric ? "left" : "left"}
                              style={{
                                width: hc.minWidth,
                                fontSize: 12,
                                paddingRight: hc.isLast ? 30 : 0,
                                paddingLeft:hc.disablePadding ? 20:0,
                              }}
                              key={hc?.id}
                            >
                              {!hc?.isInputBox ? (
                               `${row[hc?.id] || (from == "ACT"?"0":"")}${hc?.addsValue || ""} `
                              ) : (
                                <ThemeProvider theme={customTheme(outerTheme)}>
                                  <OutlinedInput
                                    sx={{
                                      "&:hover fieldset": {
                                        borderColor: "lightgrey !important",
                                      },
                                      "&:focus fieldset": {
                                        borderColor: "lightgrey !important",
                                      },
                                      height: 35,
                                      width:135,
                                    }}
                                    title={
                                      !isItemSelected &&
                                      "please select activity"
                                    }
                                    value={row[hc?.id]}
                                    disabled={!isItemSelected}
                                    type="text"
                                    onChange={(e) => handleInputAction(e, row)}
                                  />
                                </ThemeProvider>
                              )}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })
              ) : (
                <LoadWhile />
              )
              // <TableRow >
              //   {status? <TableCell colSpan={headCells?.length+1} sx={{textAlign:"center"}}><Skeleton animation="wave" variant="rectangular" sx={{ height:40,width:'100%'}} /></TableCell>
              //   :
              //   <TableCell colSpan={headCells?.length+1} sx={{textAlign:"center",backgroundColor:'red'}}> No records found!</TableCell>
              //   }
              // </TableRow>
            }
            {/* {emptyRows > 0 && (
              <TableRow
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>

          {isTableFooter && (
            <TableFooter>
              <StyledTableCell style={{ width: "6%",padding:5 }}></StyledTableCell>
              <StyledTableCell style={{ width: 200,paddingLeft:20 }} align={"left"}>
                Total
              </StyledTableCell>
              {listOfFtValues?.length>0&&listOfFtValues.map((itm,index)=>{
                return(<StyledTableCell
                style={{ width: headCells?.length > 0 ?headCells[index+1].minWidth :'25%',paddingLeft:index === 0 ? showWeighted : 0}}
                align={"left"}
                key={index}
              >
                {itm}
              </StyledTableCell>)})}
              {/* <StyledTableCell
                style={{ width: "25%",paddingLeft:19}}
                align={"left"}
              >
                {weightedDiscountValue}
              </StyledTableCell>
              <StyledTableCell style={{ width: "18%",paddingLeft:0 }} align={"left"}>
                {PyconditionalValue}
              </StyledTableCell>
              <StyledTableCell style={{ width: "18%",paddingLeft:0 }} align={"left"}>
                {SimconditionalValue}
              </StyledTableCell>
              <StyledTableCell style={{ width: "18%",paddingLeft:0 }} align={"left"}>
                {LastyrconditionalValue}
              </StyledTableCell> */}
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {isPagination && rows?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Number(totalCount)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export default CustomTable