import { Fragment, useEffect, useState, forwardRef } from "react";
import { apiURL } from "../../services/apiUrlConfig";
import { getRequest } from "../../services/apiUtils";
import { openSnackbar } from "../../components/ui/Toastr";
import Loader from "../../components/ui/Loader";
// eslint-disable-next-line no-unused-vars
import { useDispatch,useSelector } from "react-redux";


// material-ui
import {
  Box,
  Grid,
  List,
  ListItemText,
  Typography,
  ListItem,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import MainCard from "../../components/MainCard";
// assets
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
// import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import FruitImg from "../../assets/images/fruit.svg";
import MonthCount from "./MonthCount";
// import PostOrComments from "./PostOrComments";
import "./index.scss";
import useSortData from "../../hooks/useSortData";
import SortUpIcon from "../../assets/images/swap_up.svg";
import SortDownIcon from "../../assets/images/swap_down.svg";
import FlipMove from "react-flip-move";
import VisitorsChart from "./VisitorsChart";
import ActiveUsers from "./ActiveUsers";
import {ErrorMsg} from "../../utils/helper";
import { Chart, registerables } from 'chart.js';
import Authorization from "../../utils/authorization";
Chart.register(...registerables);


// const commentsList = [
//   { primary: "20", secondary: "Pending", primaryColor: "#D0021B" },
//   { primary: "24", secondary: "Resolved", primaryColor: "#707070" },
// ];
// const rotateAnimation = {
//   transition: ".6s ease-in-out",
//   "&:hover": { rotate: "360deg" },
// };
const mvmList = [
  {
    primary: "Profile",
    count: 0,
    icon: <PersonOutlineOutlinedIcon />,
    key: "PROFILE_SCREEN",
  },
  {
    primary: "Photo Reel",
    count: 0,
    icon: <PhotoLibraryOutlinedIcon />,
    key: "PHOTOREEL_SCREEN",
  },
  {
    primary: "Products",
    count: 0,
    icon: <img src={FruitImg} alt="Products" />,
    key: "PRODUCTS_SCREEN",
  },
  {
    primary: "Activities",
    count: 0,
    icon: <AssignmentOutlinedIcon />,
    key: "ACTIVITIES_SCREEN",
  },
];





const FunctionalArticle = forwardRef((props, ref) => (
  <div ref={ref}>
    <ListItem
      key={props.id}
      sx={{
        backgroundColor: "#F5F5F5",
        margin: "5px 0px",
        borderRadius: "8px",
      }}
      secondaryAction={
        <Box>
          <Typography variant="h6" color="#66B512">
            {props?.count}
            <Typography
              component="span"
              variant="caption"
              sx={{ color: "#CCD6D9", ml: 1 }}
            >
              Visitors
            </Typography>
          </Typography>
        </Box>
      }
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        primary={props.primary}
        primaryTypographyProps={{
          color: "#585858",
          ml: 1,
        }}
      />
    </ListItem>
  </div>
));
const MvmListArticles = ({ articles }) => (
  <FlipMove
    staggerDurationBy="30"
    duration={500}
    enterAnimation={"accordionVertical"}
    leaveAnimation={"accordionVertical"}
    typeName="ul"
  >
    {articles.map((article, index) => (
      <FunctionalArticle key={index + 1} {...article} />
    ))}
  </FlipMove>
);

export default function Dashboard() {
  const [mvmData, setMvmData] = useState(mvmList);
  const [isLoader, SetLoader] = useState(false);
  const { sortedList, requestSort, sortConfig } = useSortData(mvmData,{direction:"descending",key:"count"});
  const { countryCode } = useSelector((state) => state.common);
  const authUser = Authorization.getAuthUser();

  useEffect(() => {
    getMVMList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);


  

  const getMVMList = () => {
    const { analytics } = apiURL;
    SetLoader(true);
    getRequest({
      url:`${analytics.getMVM}/${authUser?.id}`,
      queryParams: { countryCode: countryCode === "ALL" ? null : countryCode}
    })
      .then((response) => {
        SetLoader(false);
        const mvmResult = response?.body?.data;
        if (mvmResult?.length > 0) {
          const result = mvmData.map((md) => {
            mvmResult.map((mr) => {
              if (mr.eventname === md.key) {
                return (md = { ...md, count: mr.totalvisits });
              }
              return md;
            });
            return md;
          });
          setMvmData(result);
        }else{
          setMvmData(mvmList);
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes= ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  const callbackLoader = (isLoader) => {
    SetLoader(isLoader);
  };
  return (
    <Fragment>
      {isLoader && <Loader />}
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        {/* row 1 */}
        <MonthCount  callbackLoader={callbackLoader} selectedCountryCode={countryCode} loggedUser={authUser}/>
        {/* row 2 */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <MainCard
            title="Visitors by month"
            elevation={0}
            border={false}
            content={true}
            contentSX={{ p: 1 }}
            headerSX={{ p: 1, color: "#707070" }}
          >
              <VisitorsChart callbackLoader={callbackLoader} selectedCountryCode={countryCode} loggedUser={authUser} />
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MainCard
            title="Current month users"
            elevation={0}
            border={false}
            content={true}
            contentSX={{ p: 1 }}
            headerSX={{ p: 1, color: "#707070" }}
          >
              <ActiveUsers callbackLoader={callbackLoader}  selectedCountryCode={countryCode} loggedUser={authUser} />
          </MainCard>
        </Grid>
        {/* Most viewed modules */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MainCard
            title="Most viewed modules"
            elevation={0}
            border={false}
            content={true}
            contentSX={{ p: 1 }}
            headerSX={{ p: 1, color: "#707070" }}
            secondary={
              <IconButton size="small" onClick={() => requestSort("count")}>
                <img
                  src={
                    sortConfig?.direction === "ascending"
                      ? SortDownIcon
                      : SortUpIcon
                  }
                  alt="sort icon"
                />
              </IconButton>
            }
            // isSimpleBar={true}
            // scrollHeight={275}
          >
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                p: 0,
              }}
            >
              <MvmListArticles articles={sortedList} />
            </List>
          </MainCard>
        </Grid>
        {/* row 3 */}
        {/* Reported Posts */}
        {/* <PostOrComments
          title="Reported Posts"
          data={commentsList}
          rotateAnimation={rotateAnimation}
        /> */}
        {/* Reported Comments */}
        {/* <PostOrComments
          title="Reported Comments"
          data={commentsList}
          rotateAnimation={rotateAnimation}
        /> */}
        {/* Top Reported Accounts */}
        {/* <Grid item xs={12} sm={12} md={8} lg={6}>
          <MainCard
            title="Top Reported Accounts"
            elevation={0}
            border={false}
            content={true}
            contentSX={{ p: 0 }}
            headerSX={{ p: 1, color: "#707070" }}
          >
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                display: "flex",
                p: 0,
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={Person1} alt="Person" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Amy Hastings"
                  secondary="Spain"
                  primaryTypographyProps={{
                    color: "#585858",
                  }}
                  secondaryTypographyProps={{
                    color: "#96A8AE",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={Person2} alt="Person1" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Shanaya Singh"
                  secondary="India"
                  primaryTypographyProps={{
                    color: "#585858",
                  }}
                  secondaryTypographyProps={{
                    color: "#96A8AE",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={Person3} alt="Person3" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Shawn Ferris"
                  secondary="Switzerland"
                  primaryTypographyProps={{
                    color: "#585858",
                  }}
                  secondaryTypographyProps={{
                    color: "#96A8AE",
                  }}
                />
              </ListItem>
            </List>
          </MainCard>
        </Grid> */}
      </Grid>
    </Fragment>
  );
}
