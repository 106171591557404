import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    saveDocumentDetails: {},
    saveDocumentLoading: false,
    saveDocumentError: false,
    saveDocument: []
}
const userReducer = createSlice({
    name: 'saveDocument',
    initialState,
    reducers: {
        saveDocumentData(state, action) {
            state.saveDocument = {...action.payload}
        },
        saveDocumentHashData(state) {
            return { ...state, saveDocumentLoading: true };
        },
        saveDocument(state) {
            return { ...state, saveDocumentLoading: true };
        },
        saveDocument_successful(state, action) {
            return {
                ...state, saveDocumentDetails: action.payload,
                saveDocumentError: false
            }
        },
        saveDocument_error(state, action) {
            return {
                ...state, saveDocumentDetails: action.payload.body,
                saveDocumentError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, saveDocumentLoading: false,
                saveDocumentError: false,
                saveDocumentDetails: {}
            };
        },
    }
})
export const {
    saveDocumentData,
    saveDocumentHashData,
    saveDocument_successful,
    saveDocument_error,
    resetStatus
} = userReducer.actions;

export default userReducer.reducer