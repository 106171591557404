import {  createSlice } from '@reduxjs/toolkit'
const initialState = {
  activitiesList: [],
  activitiesloading: true,
}

const userReducer = createSlice({

  name: 'activitiesReducer',
  initialState,
  reducers: {
    getActivities(state) {
      state.activitiesloading = true;
    },
    getActivities_successful(state, action) {
      state.activitiesList = action.payload.body;
      state.activitiesloading = false;
    },
    getActivities_error(state) {
      state.activitiesloading = false;
    },
  },
})

export const {
  getActivities,
  getActivities_successful,
  getActivities_error,
} = userReducer.actions;

export default userReducer.reducer
