// import { useEffect, useState } from "react";
import LeaderBoardTable from "./LeaderBoardTable";
// import { apiURL } from "../../services/apiUrlConfig";
// import { getRequest } from "../../services/apiUtils";
// import { openSnackbar } from "../../components/ui/Toastr";
// import { ErrorMsg } from "../../utils/helper";

const columns = [
  {
    width: 25,
    label: "",
    dataKey: "serial",
  },
  {
    width: 120,
    label: "Country",
    dataKey: "country",
  },
  {
    width: 100,
    label: "Planned",
    dataKey: "createdLoc",
    numeric: true,
  },
  {
    width: 100,
    label: "Completed",
    dataKey: "completedLoc",
    numeric: true,
  },
];

const TrialLeader = ({data}) => {
  return (
    <LeaderBoardTable
      title="Trial Location Leaderboard"
      rows={data}
      headers={columns}
    />
  );
};

export default TrialLeader;
