import React, { useState, useEffect } from "react";
// import { Chart as ChartJS } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { apiURL } from "../../services/apiUrlConfig";
import { getRequest } from "../../services/apiUtils";
import { openSnackbar } from "../../components/ui/Toastr";
import { ErrorMsg } from "../../utils/helper";

const data = {
  labels: [],
  datasets: [
    {
      label: "visitors",
      data: [],
      backgroundColor: ["#abed55"],
      borderColor: ["#abed55"],
      borderWidth: 1,
      borderRadius: 20,
      barThickness: 20,
    },
  ],
};
const config = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      grace: "50%",
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const VisitorsChart = ({ callbackLoader, selectedCountryCode, loggedUser }) => {
  const [visitByMthData, setVisitByMthData] = useState(data);

  useEffect(() => {
    getLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode]);
  const getLoadData = () => {
    const { analytics } = apiURL;
    callbackLoader(true);
    getRequest({
      url:`${analytics.getVisitByMonth}/${loggedUser?.id}`,
      queryParams: {
        countryCode: selectedCountryCode === "ALL" ? null : selectedCountryCode
      },
    })
      .then((response) => {
        callbackLoader(false);
        const result = response?.body?.data;
        if (result) {
          let dataSetsArray = visitByMthData?.datasets[0];
          let labelData = result?.months;
          const updateLabel = labelData?.length>0&&labelData.map((label) => {
            if (label) {
              const minLetter3 = label.substring(0, 3);
              return (label = minLetter3);
            }
            return label;
          });
          dataSetsArray = { ...dataSetsArray, data: result?.data };
          setVisitByMthData({
            ...visitByMthData,
            labels: updateLabel,
            datasets: [dataSetsArray],
          });
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  return <Bar options={config} data={visitByMthData} />;
};

export default VisitorsChart;
