var validator = {
  validateAlpha: function (element) {
    return /^[A-Za-z ]+$/.test(element);
  },
  validateEmail: function (element) {
    return element && element.trim()
      ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(element)
      : true;
  },
  validatePassword: function (element) {
    return element && element.trim()
      ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
        element
      )
      : true;
  },
  validateNumeric: function (element) {
    return element && element.trim()
      ? /^-?\d*(\.\d+)?$/.test(element)
      : true;
  },
  validateCountryMobileNo: function (element) {
    return element && element.trim()
      ? /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?)[0-9]{1}$/.test(element)
      : true;
  },
  validateAlphaFirstChar: function (element) {
    return /^[A-Za-z ]+$/.test(element);
  },
}


export default validator;
