/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from "react";
import CustomTable, { CustomTableToolbar } from "../../../components/ui/Table";
import Box from "@mui/material/Box";
import ReactSelect from "../../../components/third-party/ReactSelect";
import {
  getAccountsSelected,
  getContractsList,
} from "../../../store/reducers/contracts";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../../components/ui/Button";
// import Loader from "../../../components/ui/Loader";
import { apiURL } from "../../../services/apiUrlConfig";
import { getRequest } from "../../../services/apiUtils";
import { openSnackbar } from "../../../components/ui/Toastr";
import { ErrorMsg } from "../../../utils/helper";
import Authorization from "../../../utils/authorization";
import util from 'util'


const headCells = [
  {
    id: "accountname",
    numeric: false,
    disablePadding: true,
    label: "Accounts",
    minWidth: 135,
  },
  {
    id: "country__c",
    numeric: false,
    disablePadding: false,
    label: "Country",
    minWidth: 100,
  },
  {
    id: "targetvalue",
    numeric: true,
    disablePadding: false,
    label: "Target value",
    minWidth: 100,
  },
  {
    id: "pylevel",
    numeric: true,
    disablePadding: false,
    label: "PY level",
    minWidth: 95,
  },
  {
    id: "pysales",
    numeric: true,
    disablePadding: false,
    label: "PY sales (€)",
    minWidth: 100,
  },
  {
    id: "pydiscountpercent",
    numeric: true,
    disablePadding: false,
    label: "PY trade discount",
    minWidth: 110,
    addsValue: "%",
  },
  {
    id: "pyconditionaldiscountpercent",
    numeric: true,
    disablePadding: false,
    label: "PY conditional",
    minWidth: 100,
    addsValue: "%",
  },
  {
    id: "pyunconditionaldiscountpercent",
    numeric: true,
    disablePadding: false,
    label: "PY unconditional",
    minWidth: 100,
    addsValue: "%",
  },
];

const ContractsTable = () => {
  const [numSelected, setNumSelected] = useState(0);
  const [isLoader, SetLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownCountryOptions, setDropdownCountryOptions] = useState([]);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { accounts, contractsList } = useSelector((state) => state.contracts);
  const authUser = Authorization.getAuthUser();
  const isAmericanCountry = Authorization.isAmerica()
  const { countryList } = useSelector((state) => state.common);
  const [filters, setFilters] = useState({ countrycode: null, pylevel: null });
  const [countryHeaderCells ,setCountryHeaderCells] = useState([...headCells])
  useEffect(() => {
    if (countryList?.length > 0) {
      let options = [{ value: "ALL", label: "ALL" }];
      const selectOptions = countryList.map((rst) => {
        let obj = { value: rst?.countrycode, label: rst?.countryname };
        return (obj = { ...obj });
      });
      const updatedOptions = [...options, ...selectOptions];
      setDropdownCountryOptions(updatedOptions);
    }
    // if (contractsList?.contracts?.length > 0) {
    //   setTotalCount(contractsList?.count || 0);
    //   setTableData(contractsList?.contracts);
    // } else {
    getContractsTableList();
    getCountryLoadData();
    // }
    if(!isAmericanCountry){
      const newCountryCells = headCells?.length > 0 && headCells.filter((itm)=>{
        // eslint-disable-next-line eqeqeq
        return itm?.id != 'targetvalue'
      })
        
        setCountryHeaderCells([...newCountryCells])
     }else {
      const newCountryCells = headCells&&headCells.map((itm)=>{
         const crncrt = itm.label.replace('€','$')
         const aftCrncrt = {...itm,label:crncrt}
         return aftCrncrt
      })

      //        itm?.label.replace('€',   '$')

      setCountryHeaderCells([...newCountryCells])
     }
  }, []);
  const getCountryLoadData = () => {
    const { analytics } = apiURL;
    const getApi = analytics.getCountry ?? ''
    const userDetaisl = Authorization.authUser
    const getCountryIntrfce = util.format(getApi, userDetaisl.id)
    SetLoader(true);
    getRequest({
      url: getCountryIntrfce,
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body?.data;
        if (result?.length > 0) {
          let options = [{ value: "ALL", label: "All country" }];
          const selectOptions = result.map((rst) => {
            let obj = { value: rst?.countrycode, label: rst?.countryname };
            return (obj = { ...obj });
          });
          const updatedOptions = [...options, ...selectOptions];
          setDropdownCountryOptions(updatedOptions);
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  const getFilterData = (filterDatas) => {
    setNumSelected(filterDatas?.selectedRow?.length);
    dispatch(getAccountsSelected({ accounts: filterDatas?.selectedRow }));
  };

  const getContractsTableList = (page = 0, rowsPerPage = 10, filterParams) => {
    const {
      ppp: { getContracts },
    } = apiURL;
    let queryObj = { status: "PENDING", index: page, limit: rowsPerPage };
    SetLoader(true);
    let filter ={...filterParams}
    filter.countrycode= filter.countrycode==="ALL"? null:filter.countrycode
    getRequest({
      url: `${getContracts}/${authUser?.id}`,
      queryParams: { ...queryObj, ...filter },
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body?.contracts;
        setTotalCount(response?.body?.count || 0);
        dispatch(getContractsList({ contractsList: response?.body }));
        if (result?.length > 0) {
          setTableData(result);
        } else {
          setTableData([]);
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  const handleSelect = (options, e) => {
    setFilters({ ...filters, [e.name]: options.value });
  };
  const applyFilters = () => {
    const filterParams = { ...filters };
    if(filterParams?.countrycode || filterParams?.pylevel){
      getContractsTableList(0, 10, filterParams);
    }
   
  };
  const handleResetFilter = () => {
    setFilters({ countrycode: null, pylevl: null });
    getContractsTableList(0, 10, {});
  };
  return (
    <Fragment>
      {/* {isLoader && <Loader />} */}
      <Box>
        <CustomTableToolbar
          numSelected={numSelected}
          sx={{ pl: "0 !important", pr: "0 !important" }}
          clearFilter={handleResetFilter}
        >
          <Box display={"flex"} gap={2}>
            <ReactSelect
              name={"countrycode"}
              options={dropdownCountryOptions}
              handleChange={(selectedOptions, e) => {
                handleSelect(selectedOptions, e);
              }}
              value={filters?.countrycode}
              id="country-test"
              label="By Country"
              width={200}
              defaultValue="ALL"
            />
            <ReactSelect
              name={"pylevel"}
              options={[
                { value: "Level 1", label: "Level 1" },
                { value: "Level 2", label: "Level 2" },
                { value: "Level 3", label: "Level 3" },
              ]}
              handleChange={(selectedOptions, e) => {
                handleSelect(selectedOptions, e);
              }}
              value={filters?.pylevel}
              id="py-level"
              label="By PY level"
              width={200}
            />
            <Box display="flex" alignItems={"end"}>
              <CustomButton
                label="Apply"
                sx={{
                  color: "#fff",
                  borderRadius: "28px",
                  backgroundColor: "rgb(0, 145, 223)",
                  opacity: "1",
                  "&:hover": {
                    backgroundColor: "#0D47A1",
                  },
                  width: "150px",
                  height: "40px",
                }}
                handleClick={applyFilters}
                className="cus-btn-arrow"
                labelClassName={"label"}
              ></CustomButton>
            </Box>
          </Box>
        </CustomTableToolbar>
        <CustomTable
          statusLoad={isLoader}
          rows={tableData}
          headCells={countryHeaderCells}
          getFilterData={getFilterData}
          isPagination={true}
          selectedChecked={accounts}
          getAPICall={getContractsTableList}
          totalCount={totalCount}
        ></CustomTable>
      </Box>
    </Fragment>
  );
};

export default ContractsTable;
