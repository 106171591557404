import LeaderBoardTable from "./LeaderBoardTable";
  
  const columns = [
    {
      width: 10,
      label: "",
      dataKey: "serial",
    },
    {
      width: 125,
      label: 'Account Name',
      dataKey: 'name',
    },
    {
      width: 100,
      label: 'Trials Completed',
      dataKey: 'trialCount',
      numeric: true,
    },
    {
      width:100,
      label: 'Assessments Done',
      dataKey: 'assessmentCount',
      numeric: true,
    }
  ];
const UserTrialLeader = ({data}) => {
  return <LeaderBoardTable title="Partner Trial Leaderboard (external)" rows={data} headers={columns} />;
};

export default UserTrialLeader;
